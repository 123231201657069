/**
 * View text block.
 * @module components/manage/Blocks/Text/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import ViewBlock from './Block/ViewBlock';
import { Grid } from 'semantic-ui-react';
/**
 * View text block class.
 * @class View
 * @extends Component
 */
const View = ({ data }) => {
  var chunkSize = 3; // items per chunk

  var rows = data.subblocks.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);
    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }
    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []);

  return (
    <div className="block textBlocks">
      <Grid stackable columns="equal">
        {rows.map((blocks, index) => (
          <Grid.Row columns={3} stretched key={'rowblock ' + index}>
            {blocks.map((subblock, index) => (
              <Grid.Column key={'sbb' + index}>
                <ViewBlock data={subblock} key={subblock.id} />
              </Grid.Column>
            ))}
          </Grid.Row>
        ))}
      </Grid>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
