import React from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { Icon } from '@plone/volto/components';
import { Button, Grid, Form, Segment } from 'semantic-ui-react';

import cx from 'classnames';

const messages = defineMessages({});

const IconsWidget = ({ value, icons, onChange, intl }) => {
  return (
    <Segment className="icons-widget">
      {value && (
        <Form.Field inline>
          <Grid>
            <Grid.Row>
              <Grid.Column width={4}>
                <FormattedMessage
                  id="Current icon"
                  defaultMessage="Icona selezionata"
                />
                :
              </Grid.Column>
              <Grid.Column width={8} textAlign="center" verticalAlign="middle">
                <Icon
                  name={icons[value]}
                  className="selected-icon"
                  size="100px"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form.Field>
      )}
      <div className="form ">
        <Grid centered verticalAlign="middle" className="grid-selection">
          <Grid.Row>
            <Grid.Column textAlign="center">
              <FormattedMessage
                id="Select one icon"
                defaultMessage="Seleziona un'icona fra quelle disponibili:"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={4}>
            {Object.keys(icons).map(name => (
              <Grid.Column key={name} textAlign="center" verticalAlign="middle">
                <Button
                  basic
                  icon
                  onClick={() => onChange(name)}
                  className={cx({ selected: value === name })}
                >
                  <Icon
                    name={icons[name]}
                    size="50px"
                    className="sidebar-icon"
                  />
                </Button>
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
      </div>
    </Segment>
  );
};

export default injectIntl(IconsWidget);
