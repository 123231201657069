/**
 * Edit text block.
 * @module components/manage/Blocks/Title/Edit
 */

import React from 'react';
import { compose } from 'redux';
import { injectIntl, defineMessages } from 'react-intl';

import { DNDSubblocks, SubblockEdit, Subblock } from 'volto-subblocks';
import { TextEditorWidget } from '@package/components';
import { SidebarPortal } from '@plone/volto/components';
import SidebarBlock from './SidebarBlock';

const messages = defineMessages({
  titlePlaceholder: {
    id: 'Title placeholder',
    defaultMessage: 'Titolo...',
  },
  descriptionPlaceholder: {
    id: 'Description placeholder',
    defaultMessage: 'Descrizione...',
  },
});
/**
 * Edit text block class.
 * @class Edit
 * @extends SubblockEdit
 */
class EditBlock extends SubblockEdit {
  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    this.state = {
      focusOn: 'title',
    };
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (__SERVER__) {
      return <div />;
    }

    return (
      <Subblock subblock={this}>
        <TextEditorWidget
          data={this.props.data}
          fieldName="title"
          selected={this.props.selected || this.state.focusOn === 'title'}
          block={this.props.block}
          onChangeBlock={this.onChange}
          placeholder={this.props.intl.formatMessage(messages.titlePlaceholder)}
          focusOn={this.focusOn}
          nextFocus="description"
        />

        <TextEditorWidget
          data={this.props.data}
          fieldName="description"
          selected={this.state.focusOn === 'description'}
          block={this.props.block}
          onChangeBlock={this.onChange}
          placeholder={this.props.intl.formatMessage(
            messages.descriptionPlaceholder,
          )}
          inlineToolbarPlugin={true}
        />
        <SidebarPortal selected={this.props.selected}>
          <SidebarBlock
            {...this.props}
            data={this.props.data}
            onChangeBlock={this.onChangeSidebar}
          />
        </SidebarPortal>
      </Subblock>
    );
  }
}

export default React.memo(compose(injectIntl, ...DNDSubblocks)(EditBlock));
