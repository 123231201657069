const getDatesAndTimes = item => {
  let dates = '';
  let times = '';

  if (item.start || item.end) {
    let start = item.start ? new Date(item.start) : null;
    let end = item.end ? new Date(item.end) : start;

    let locale = 'it';

    if (start && end) {
      if (start.getFullYear() === end.getFullYear()) {
        dates += start.getFullYear();
        if (start.getMonth() === end.getMonth()) {
          dates = start.toLocaleString(locale, { month: 'long' }) + ' ' + dates;
          if (start.getDate() === end.getDate()) {
            dates = start.getDate() + ' ' + dates;
          } else {
            dates = start.getDate() + '-' + end.getDate() + ' ' + dates;
          }
        } else {
          dates =
            start.getDate() +
            ' ' +
            start.toLocaleString(locale, { month: 'long' }) +
            ' - ' +
            end.getDate() +
            ' ' +
            end.toLocaleString(locale, { month: 'long' }) +
            ' ' +
            dates;
        }
      } else {
        dates =
          start.getDate() +
          ' ' +
          start.toLocaleString(locale, { month: 'long' }) +
          ' ' +
          start.getFullYear() +
          ' - ' +
          end.getDate() +
          ' ' +
          end.toLocaleString(locale, { month: 'long' }) +
          ' ' +
          end.getFullYear();
      }

      let startTime = start.toLocaleString(locale, {
        hour: '2-digit',
        minute: '2-digit',
      });
      let endTime = end.toLocaleString(locale, {
        hour: '2-digit',
        minute: '2-digit',
      });

      times = 'dalle ' + startTime + ' alle ' + endTime;
    }

    dates = dates.length == 0 ? null : dates;
    times = times.length == 0 ? null : times;
  }
  return [dates, times];
};

export default getDatesAndTimes;
