/**
 * View image block.
 * @module components/Blocks/FullImage/View
 */

import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createContent } from '@plone/volto/actions';

/**
 * View image block class.
 * @class View
 * @extends Component
 */
const View = ({ data, content, detached }) => {
  return (
    <div
      className={cx('block pageDescription', {
        detached,
      })}
    >
      {content.description}
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default compose(
  connect(
    state => ({
      request: state.content.create,
      content: state.content.data,
    }),
    { createContent },
  ),
)(View);
