import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { injectIntl } from 'react-intl';

import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';

const LinkItem = ({ item, description, defaultImage, intl, isEditMode }) => {
  return (
    <div className="link-item">
      <div className={cx('header')}>
        <h3>
          <ConditionalLink
            to={flattenToAppURL(item.url)}
            condition={!isEditMode}
            target="_blank"
            aria-label={item.title}
          >
            {item.title}
          </ConditionalLink>
        </h3>
      </div>
      {item.description && <div className="body">{item.description}</div>}
    </div>
  );
};

LinkItem.propTypes = {
  item: PropTypes.object.isRequired,
  description: PropTypes.bool,
};

export default injectIntl(LinkItem);
