/**
 * Tags component.
 * @module components/Tags/Tags
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Label } from 'semantic-ui-react';
import { getKeywordColors } from '~/actions';
/**
 * Tags image block class.
 * @class Tags
 * @extends Component
 */

class Tags extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    tags: PropTypes.array,
    getKeywordColors: PropTypes.func.isRequired,
    keyword_colors: PropTypes.any,
  };

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    if (this.props.tags && this.props.tags.length > 0) {
      this.props.getKeywordColors();
    }
  }

  render() {
    const { tags, keyword_colors, loading } = this.props;
    if (!loading && tags && tags.length > 0) {
      return (
        <>
          <div className="tags">
            {tags.map(subj => (
              <Label key={subj} className={keyword_colors[subj]}>
                {subj}
              </Label>
            ))}
          </div>
        </>
      );
    }
    return null;
  }
}

export default connect(
  (state, props) => {
    //mapStateToProps
    const keyword_colors = state.keywordColors;

    if (keyword_colors) {
      return {
        keyword_colors: keyword_colors.results,
        loading: keyword_colors.loadingResults,
      };
    }
    return {};
  },
  { getKeywordColors },
)(Tags);
