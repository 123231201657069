import React from 'react';
import PropTypes from 'prop-types';

import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { settings } from '~/config';
import { FormattedMessage, injectIntl } from 'react-intl';

import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';
import { Tags } from '@package/components';

const ImageItem = ({
  item,
  description,
  showImage = true,
  linkMore = true,
  isEditMode,
}) => {
  const getImage = (item) => {
    let image = item[settings.listingPreviewImageField];
    if (image) {
      image = image.scales.preview.download;
    } else if (item.blocks) {
      //get image from first image block
      Object.keys(item.blocks).map((k) => {
        let block = item.blocks[k];
        if (block['@type'] === 'image') {
          image = block.url.includes(settings.apiPath)
            ? `${flattenToAppURL(block.url)}/@@images/image`
            : block.url;
        }
      });
    }
    if (!image) {
      image = DefaultImageSVG;
    }
    return image;
  };

  const image = getImage(item);
  return (
    <div className="image-item">
      {showImage && (
        <div className="header">
          <ConditionalLink
            to={flattenToAppURL(item['@id'])}
            condition={!linkMore && !isEditMode}
            aria-label={item.title}
          >
            <div className="image">
              <img
                src={image}
                alt={item.title}
                className={image === DefaultImageSVG ? 'default' : ''}
              />
            </div>
          </ConditionalLink>
        </div>
      )}
      {item.title && (
        <div className="body">
          <ConditionalLink
            to={flattenToAppURL(item['@id'])}
            condition={!linkMore}
            aria-label={item.title}
          >
            {item.title}
          </ConditionalLink>
        </div>
      )}
    </div>
  );
};

ImageItem.propTypes = {
  item: PropTypes.object.isRequired,
  description: PropTypes.bool,
  showImage: PropTypes.bool,
  linkMore: PropTypes.bool,
};

export default injectIntl(ImageItem);
