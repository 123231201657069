/**
 * Edit text block.
 * @module components/manage/Blocks/Title/Edit
 */

import React from 'react';
import { compose } from 'redux';
import { injectIntl, defineMessages } from 'react-intl';
import { Placeholder, Button, Input } from 'semantic-ui-react';
import { SidebarPortal } from '@plone/volto/components';
import { DNDSubblocks, SubblockEdit, Subblock } from 'volto-subblocks';
import { TextEditorWidget } from '@package/components';
import SidebarBlock from './SidebarBlock';
import ViewIcon from './ViewIcon';

const messages = defineMessages({
  iconPlaceholder: {
    id: 'Icon placeholder',
    defaultMessage: "Seleziona un'icona...",
  },
  descriptionPlaceholder: {
    id: 'Description placeholder',
    defaultMessage: 'Descrizione...',
  },
  textOnButtonPlaceholder: {
    id: 'Text on button',
    defaultMessage: 'Testo del bottone...',
  },
});
/**
 * Edit text block class.
 * @class Edit
 * @extends Component
 */
class EditBlock extends SubblockEdit {
  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    this.state = {
      focusOn: 'description',
    };
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (__SERVER__) {
      return <div />;
    }
    return (
      <Subblock subblock={this}>
        {!this.props.data.icon && (
          <Placeholder style={{ height: 50, width: 50 }} className="block-icon">
            <Placeholder.Image />
          </Placeholder>
        )}
        <ViewIcon icon={this.props.data.icon} size="40px" />
        <TextEditorWidget
          data={this.props.data}
          fieldName="description"
          selected={this.state.focusOn === 'description'}
          block={this.props.block}
          onChangeBlock={this.onChange}
          placeholder={this.props.intl.formatMessage(
            messages.descriptionPlaceholder,
          )}
        />
        {this.props.data.with_button && (
          <Button primary>
            <Input
              id="text_on_button"
              name="text_on_button"
              value={this.props.data.text_on_button || ''}
              onChange={({ target }) => {
                let value = target.value === '' ? undefined : target.value;
                this.onChange({
                  ...this.props.data,
                  text_on_button: value,
                });
              }}
              placeholder={this.props.intl.formatMessage(
                messages.textOnButtonPlaceholder,
              )}
            />
          </Button>
        )}
        <SidebarPortal selected={this.props.selected}>
          <SidebarBlock
            {...this.props}
            data={this.props.data}
            onChangeBlock={this.onChangeSidebar}
          />
        </SidebarPortal>
      </Subblock>
    );
  }
}

export default React.memo(compose(injectIntl, ...DNDSubblocks)(EditBlock));
