/**
 * Edit icons block.
 * @module components/manage/Blocks/Title/Edit
 */

import React from 'react';
import { compose } from 'redux';
import { injectIntl, defineMessages } from 'react-intl';
import { Menu } from 'semantic-ui-react';
import {
  withDNDContext,
  SubblocksEdit,
  SubblocksWrapper,
} from 'volto-subblocks';
import { TextEditorWidget } from '@package/components';
import EditBlock from './Block/EditBlock';

const messages = defineMessages({
  title: {
    id: 'Title',
    defaultMessage: 'Titolo...',
  },
});
/**
 * Edit icons block class.
 * @class Edit
 * @extends Component
 */
class Edit extends SubblocksEdit {
  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (__SERVER__) {
      return <div />;
    }

    return (
      <SubblocksWrapper node={this.node}>
        <Menu vertical secondary>
          <Menu.Item header>
            <h2 className="plaster">
              <TextEditorWidget
                data={this.props.data}
                fieldName="title"
                block={this.props.block}
                selected={true}
                onChangeBlock={(ret) =>
                  this.props.onChangeBlock(this.props.block, ret)
                }
                placeholder={this.props.intl.formatMessage(messages.title)}
                inlineToolbarPlugin={false}
              />
            </h2>
          </Menu.Item>
          {this.state.subblocks.map((subblock, subindex) => (
            <Menu.Item
              key={subblock.id}
              className={subblock.with_button ? 'with_button' : ''}
            >
              <EditBlock
                data={subblock}
                index={subindex}
                selected={this.isSubblockSelected(subindex)}
                {...this.subblockProps}
                openObjectBrowser={this.props.openObjectBrowser}
              />
            </Menu.Item>
          ))}

          {this.props.selected && this.renderAddBlockButton()}
        </Menu>
      </SubblocksWrapper>
    );
  }
}

export default React.memo(compose(injectIntl)(withDNDContext(Edit)));
