import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import cx from 'classnames';

import { ButtonLink, ItemTemplates } from '@package/components';

const DefaultTemplate = ({
  items,
  isEditMode,
  title,
  titleColor,
  linkMore,
  align,
}) => {
  return (
    <div className={cx('default-template', align)}>
      {title && <h2 className={cx('square', titleColor)}>{title}</h2>}

      {items.map(item => {
        let ItemTemplate =
          ItemTemplates[item['@type']] || ItemTemplates.Default;

        return (
          <ItemTemplate
            item={item}
            showImage={false}
            linkMore={false}
            key={item['@id']}
            description={true}
            isEditMode={isEditMode}
          />
        );
      })}

      {linkMore?.href && (
        <div className="bottom">
          <ButtonLink
            to={linkMore.href}
            title={linkMore.title}
            color="primary"
          />
        </div>
      )}
    </div>
  );
};

DefaultTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default injectIntl(DefaultTemplate);
