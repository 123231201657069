import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { injectIntl, defineMessages } from 'react-intl';

import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { settings } from '~/config';

import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';
import { Tags } from '@package/components';

import getDatesAndTimes from '@package/helpers/EventUtils';

const messages = defineMessages({
  view_details: {
    id: 'View details',
    defaultMessage: 'Vedi i dettagli',
  },
  event_where: {
    id: 'Event where',
    defaultMessage: 'Dove',
  },
  event_when: {
    id: 'Event when',
    defaultMessage: 'Quando',
  },
  event_attendees: {
    id: 'Event attendees',
    defaultMessage: 'Partecipanti',
  },
  event_contacts: {
    id: 'Event contacts',
    defaultMessage: 'Contatti',
  },
  event_url: {
    id: 'Event url',
    defaultMessage: 'URL',
  },
});

const EventItem = ({ item, description, defaultImage, intl, isEditMode }) => {
  defaultImage = defaultImage == null ? true : defaultImage;
  const getImage = (item) => {
    let image = item[settings.listingPreviewImageField];
    if (image) {
      image = image.scales.preview.download;
    } else if (item.blocks) {
      //get image from first image block
      Object.keys(item.blocks).map((k) => {
        let block = item.blocks[k];
        if (block['@type'] === 'image') {
          image = block.url.includes(settings.apiPath)
            ? `${flattenToAppURL(block.url)}/@@images/image`
            : block.url;
        }
      });
    }
    if (!image && defaultImage) {
      image = DefaultImageSVG;
    }
    return image;
  };

  const image = getImage(item);

  let datesAndTimes = getDatesAndTimes(item);
  let dates = datesAndTimes[0];
  let times = datesAndTimes[2];

  return (
    <div className="event-item">
      <div className={cx('header', image == null && 'no-image')}>
        {image && (
          <div className="image">
            <img
              src={image}
              alt={item.title}
              className={image === DefaultImageSVG ? 'default' : ''}
            />
          </div>
        )}
        {dates && <div className="dates">{dates}</div>}
      </div>
      <div className="body">
        <Tags tags={item.subjects} />

        <div className="listing-body">
          <h3>{item.title ? item.title : item.id}</h3>
          {/* {description && item.description && <div>{item.description}</div>} */}
          {description && (
            <div className="infos">
              {item.location && (
                <div>
                  <div className="title">
                    {intl.formatMessage(messages.event_where)}:
                  </div>
                  <div>{item.location}</div>
                </div>
              )}
              {times && (
                <div>
                  <div className="title">
                    {intl.formatMessage(messages.event_when)}:
                  </div>
                  <div>{times}</div>
                </div>
              )}
              {item.attendees && item.attendees.length > 0 && (
                <div>
                  <div className="title">
                    {intl.formatMessage(messages.event_attendees)}:
                  </div>
                  <div>
                    {item.attendees.map((a, index) => (
                      <span key={index}>
                        {a} {index < item.attendees.length - 1 && ','}
                      </span>
                    ))}
                  </div>
                </div>
              )}
              {(item.contact_name ||
                item.contact_phone ||
                item.contact_email) && (
                <div>
                  <div className="title">
                    {intl.formatMessage(messages.event_contacts)}:
                  </div>
                  <div>
                    {item.contact_name && (
                      <>
                        {item.contact_name}
                        <br />
                      </>
                    )}
                    {item.contact_phone && (
                      <>
                        {item.contact_phone}
                        <br />
                      </>
                    )}
                    {item.contact_email && (
                      <>
                        {item.contact_email}
                        <br />
                      </>
                    )}
                  </div>
                </div>
              )}
              {item.event_url && (
                <div>
                  <div className="title">
                    {intl.formatMessage(messages.event_url)}:
                  </div>
                  <div>
                    <a
                      href={item.event_url}
                      target="_blank"
                      aria-label={item.event_url}
                    >
                      {item.event_url}
                    </a>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="footer">
        <ConditionalLink
          to={flattenToAppURL(item['@id'])}
          condition={!isEditMode}
          aria-label={intl.formatMessage(messages.view_details)}
        >
          {intl.formatMessage(messages.view_details)}
        </ConditionalLink>
      </div>
    </div>
  );
};

EventItem.propTypes = {
  item: PropTypes.object.isRequired,
  description: PropTypes.bool,
};

export default injectIntl(EventItem);
