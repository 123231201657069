/**
 * EventView view component.
 * @module components/theme/View/EventView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from '@plone/volto/helpers';
import { Container, Grid, Image } from 'semantic-ui-react';

import { defineMessages, injectIntl } from 'react-intl';
import { map } from 'lodash';

import getDatesAndTimes from '@package/helpers/EventUtils';
import {
  flattenHTMLToAppURL,
  flattenToAppURL,
  getBlocksFieldname,
  getBlocksLayoutFieldname,
  hasBlocksData,
  getBaseUrl,
} from '@plone/volto/helpers';

import { settings, blocks } from '~/config';

const messages = defineMessages({
  event_details: {
    id: 'Event details',
    defaultMessage: "Dettagli dell'evento",
  },
  where: {
    id: 'Event where',
    defaultMessage: 'Dove',
  },
  when: {
    id: 'Event when',
    defaultMessage: 'Quando',
  },
  attendees: {
    id: 'Event attendees',
    defaultMessage: 'Partecipanti',
  },
  contacts: {
    id: 'Event contacts',
    defaultMessage: 'Contatti',
  },
  url: {
    id: 'Event url',
    defaultMessage: 'URL',
  },
  unknownBlock: {
    id: 'Unknown Block',
    defaultMessage: 'Unknown Block {block}',
  },
});

/**
 * EventView view component class.
 * @function EventView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const EventView = ({ content, intl, location }) => {
  const blocksFieldname = getBlocksFieldname(content);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(content);
  let datesAndTimes = getDatesAndTimes(content);

  let dates = datesAndTimes[0];
  let times = datesAndTimes[2];
  return (
    <Container className="view-wrapper">
      <Helmet title={content.title} />
      {content.title && (
        <h1 className="documentFirstHeading">{content.title}</h1>
      )}
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={9}>
            {content.image && (
              <Image
                className="documentImage"
                alt={content.title}
                title={content.title}
                src={
                  content.image['content-type'] === 'image/svg+xml'
                    ? flattenToAppURL(content.image.download)
                    : flattenToAppURL(content.image.scales.mini.download)
                }
                floated="right"
              />
            )}
            {content.description && (
              <p className="documentDescription">{content.description}</p>
            )}

            {hasBlocksData(content)
              ? map(content[blocksLayoutFieldname].items, block => {
                  const blockType =
                    content[blocksFieldname]?.[block]?.['@type'];
                  if (['title', 'pageDescription'].indexOf(blockType) > -1)
                    return null;

                  const Block =
                    blocks.blocksConfig[blockType]?.['view'] ?? null;
                  return Block !== null ? (
                    <Block
                      key={block}
                      id={block}
                      properties={content}
                      data={content[blocksFieldname][block]}
                      path={getBaseUrl(location?.pathname || '')}
                    />
                  ) : (
                    <div key={block}>
                      {intl.formatMessage(messages.unknownBlock, {
                        block: content[blocksFieldname]?.[block]?.['@type'],
                      })}
                    </div>
                  );
                })
              : content.text && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: flattenHTMLToAppURL(content.text.data),
                    }}
                  />
                )}
          </Grid.Column>
          <Grid.Column width={3} className="event-details">
            <h3>{intl.formatMessage(messages.event_details)}</h3>
            <div className="dates">{dates}</div>
            <div className="infos">
              {content.location && (
                <div>
                  <div className="title">
                    {intl.formatMessage(messages.where)}:
                  </div>
                  <div>{content.location}</div>
                </div>
              )}
              {times && (
                <div>
                  <div className="title">
                    {intl.formatMessage(messages.when)}:
                  </div>
                  <div>{times}</div>
                </div>
              )}
              {content.attendees && content.attendees.length > 0 && (
                <div>
                  <div className="title">
                    {intl.formatMessage(messages.attendees)}:
                  </div>
                  <div>
                    {content.attendees.map((a, index) => (
                      <span key={a}>
                        {a} {index < content.attendees.length - 1 && ','}
                      </span>
                    ))}
                  </div>
                </div>
              )}
              {(content.contact_name ||
                content.contact_phone ||
                content.contact_email) && (
                <div>
                  <div className="title">
                    {intl.formatMessage(messages.contacts)}:
                  </div>
                  <div>
                    {content.contact_name && (
                      <>
                        {content.contact_name}
                        <br />
                      </>
                    )}
                    {content.contact_phone && (
                      <>
                        {content.contact_phone}
                        <br />
                      </>
                    )}
                    {content.contact_email && (
                      <>
                        <a href={'mailto:' + content.contact_email}>
                          {content.contact_email}
                        </a>
                        <br />
                      </>
                    )}
                  </div>
                </div>
              )}
              {content.event_url && (
                <div>
                  <div className="title">
                    {intl.formatMessage(messages.url)}:
                  </div>
                  <div>
                    <a
                      href={content.event_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {content.event_url}
                    </a>
                  </div>
                </div>
              )}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
EventView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default injectIntl(EventView);
