import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

class EtilometroResults extends Component {
  static propTypes = {
    results: PropTypes.object.isRequired,
    formData: PropTypes.object.isRequired,
    drinksVocabulary: PropTypes.array.isRequired,
    hasError: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    // console.log(props);
  }

  mapDrinkName = (token, drinksVocabulary) => {
    const obj = drinksVocabulary.filter(el => el.token === token);
    return obj[0].title;
  };
  renderFormData = formData => {
    return formData.map(
      (obj, index) =>
        ` ${obj.quantity} ${this.mapDrinkName(
          obj.drink,
          this.props.drinksVocabulary,
        )}${index < formData.length - 1 ? ',' : ''}`,
    );
  };
  renderGenreAndWeigth = formData => {
    const genre = formData[0].sex === 'M' ? 'un uomo' : 'una donna';
    return `${genre} di ${formData[0].weight} kg`;
  };

  render() {
    const { results, formData, hasError } = this.props;
    return (
      <div className="etilometro results">
        {formData && results && (
          <>
            <h2 className="plaster">Risultato del test</h2>
            {!hasError && (
              <div>
                <div className="alcoholLevel">
                  Tasso di alcolemia:{' '}
                  <div className="level">{results.bloodAlcoholLevel}</div>
                </div>
                <div>
                  Se hai bevuto{' '}
                  <span className="drinks">
                    {this.renderFormData(formData['formData'])}
                  </span>
                  , e sei{' '}
                  <span className="age">
                    {this.renderGenreAndWeigth(formData['formData'])}
                  </span>
                  , questi sono gli effetti alla guida:
                  <div className="effects">{results.result.effects}</div>
                </div>
                <p className="suggestions">
                  Potrai metterti alla guida tra:{' '}
                  <span className="hours">
                    {results.result.wait}{' '}
                    {parseInt(results.result.wait) > 1 ? 'ore' : 'ora'}
                  </span>
                </p>
                <p>
                  La soglia prevista dal codice della strada{' '}
                  {results.bloodAlcoholLevel > 0.5 ? '' : 'non'} e' stata
                  superata.
                </p>
                <p className="remember">
                  Ricorda: il calcolo proposto si basa su un consumo effettuato
                  nell'arco di un'ora. Se hai bevuto da un lasso di tempo
                  minore, l'effetto è maggiore.
                </p>
              </div>
            )}
            {hasError && (
              <div className="errors">Errore: {results.message}</div>
            )}
          </>
        )}
        <div className="back">
          <Button
            onClick={() => {
              this.props.doTestAgain();
            }}
            secondary
            size="big"
            content="Rifai il test"
          />
        </div>
      </div>
    );
  }
}

export default EtilometroResults;
