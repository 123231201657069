import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Segment } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import { Icon } from '@plone/volto/components';

import upSVG from '@plone/volto/icons/up-key.svg';
import downSVG from '@plone/volto/icons/down-key.svg';

import ImageSettings from './Sidebar/ImageSettings';

const messages = defineMessages({
  Image: {
    id: 'Image',
    defaultMessage: 'Immagine',
  },
  block: {
    id: 'Blocco',
    defaultMessage: 'Blocco',
  },
});

const Sidebar = ({
  data,
  block,
  onChangeBlock,
  openObjectBrowser,
  required = false,
  subblockFocused,
  intl,
}) => {
  const [activeAccIndex, setActiveAccIndex] = useState(0);

  function handleAccClick(e, titleProps) {
    const { index } = titleProps;
    const newIndex = activeAccIndex === index ? -1 : index;

    setActiveAccIndex(newIndex);
  }

  React.useEffect(() => {
    setActiveAccIndex(subblockFocused);
  }, [subblockFocused]);

  return (
    <Segment.Group raised>
      <Accordion fluid styled>
        <Accordion.Title
          active={activeAccIndex === 0}
          index={0}
          onClick={handleAccClick}
        >
          {intl.formatMessage(messages.block)} 1
          {activeAccIndex === 0 ? (
            <Icon name={upSVG} size="20px" />
          ) : (
            <Icon name={downSVG} size="20px" />
          )}
        </Accordion.Title>
        <Accordion.Content active={activeAccIndex === 0}>
          <ImageSettings
            data={data.block1}
            onChange={(field, value) => {
              onChangeBlock(block, {
                ...data,
                block1: {
                  ...data.block1,
                  [field]: value,
                },
              });
            }}
            openObjectBrowser={openObjectBrowser}
          />
        </Accordion.Content>

        <Accordion.Title
          active={activeAccIndex === 1}
          index={1}
          onClick={handleAccClick}
        >
          {intl.formatMessage(messages.block)} 2
          {activeAccIndex === 1 ? (
            <Icon name={upSVG} size="20px" />
          ) : (
            <Icon name={downSVG} size="20px" />
          )}
        </Accordion.Title>
        <Accordion.Content active={activeAccIndex === 1}>
          <ImageSettings
            data={data.block2}
            onChange={(field, value) => {
              onChangeBlock(block, {
                ...data,
                block2: {
                  ...data.block2,
                  [field]: value,
                },
              });
            }}
            openObjectBrowser={openObjectBrowser}
          />
        </Accordion.Content>

        <Accordion.Title
          active={activeAccIndex === 2}
          index={2}
          onClick={handleAccClick}
        >
          {intl.formatMessage(messages.block)} 3
          {activeAccIndex === 2 ? (
            <Icon name={upSVG} size="20px" />
          ) : (
            <Icon name={downSVG} size="20px" />
          )}
        </Accordion.Title>
        <Accordion.Content active={activeAccIndex === 2}>
          <ImageSettings
            data={data.block3}
            onChange={(field, value) => {
              onChangeBlock(block, {
                ...data,
                block3: {
                  ...data.block3,
                  [field]: value,
                },
              });
            }}
            openObjectBrowser={openObjectBrowser}
          />
        </Accordion.Content>
      </Accordion>
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  openObjectBrowser: PropTypes.func.isRequired,
};

export default injectIntl(Sidebar);
