/**
 * Gambling form reducer.
 * @module reducers/gamblingForm/gamblingFormReducer
 */

import { SEND_DATA } from '../actions';

const initialState = {
  error: null,
  hasErrror: false,
  results: {},
  loadingResults: false,
};

export const gamblingFormReducer = (state = initialState, action = {}) => {
  // console.log('[gamblingFormReducer] state: ', state)
  // console.log('[gamblingFormReducer] action:', action);
  switch (action.type) {
    case `${SEND_DATA}_PENDING`:
      return {
        ...state,
        loadingResults: true,
      };
    case `${SEND_DATA}_SUCCESS`:
      return {
        ...state,
        results: action.result,
        loadingResults: false,
      };
    case `${SEND_DATA}_FAIL`:
      return {
        ...state,
        error: action.error,
        hasError: true,
        loadingResults: false,
      };
    default:
      return state;
  }
};
