import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import cx from 'classnames';

import { ButtonLink, ItemTemplates } from '@package/components';

const PreviewTemplate = ({
  items,
  isEditMode,
  title,
  titleColor,
  linkMore,
  align,
}) => {
  return (
    <div className={cx('default-template', align)}>
      {title && <h2 className={cx('square', titleColor)}>{title}</h2>}
      <Grid stackable>
        {items.map((item) => {
          let ItemTemplate =
            ItemTemplates[item['@type']] || ItemTemplates.Default;
          return (
            <Grid.Column
              className="listing-item "
              key={item['@id']}
              tablet={6}
              computer={4}
              largeScreen={3}
              widescreen={3}
            >
              <ItemTemplate item={item} isEditMode={isEditMode} />
            </Grid.Column>
          );
        })}
      </Grid>
      {linkMore?.href && (
        <div className="bottom">
          <ButtonLink
            to={linkMore.href}
            title={linkMore.title}
            color="primary"
            aria-label={linkMore.title}
          />
        </div>
      )}
    </div>
  );
};

PreviewTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default injectIntl(PreviewTemplate);
