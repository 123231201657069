import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { Accordion, Grid, Segment, Button } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import { CheckboxWidget, Icon, TextWidget } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';

import { settings } from '~/config';

import imageSVG from '@plone/volto/icons/image.svg';
import clearSVG from '@plone/volto/icons/clear.svg';
import upSVG from '@plone/volto/icons/up-key.svg';
import downSVG from '@plone/volto/icons/down-key.svg';
import navTreeSVG from '@plone/volto/icons/nav.svg';

const messages = defineMessages({
  Image: {
    id: 'Image',
    defaultMessage: 'Immagine',
  },
  Origin: {
    id: 'Origin',
    defaultMessage: 'Origine',
  },
  AltText: {
    id: 'Alt text',
    defaultMessage: "Testo per l' Alt",
  },
  Align: {
    id: 'Alignment',
    defaultMessage: 'Allineamento',
  },
  LinkSettings: {
    id: 'Link settings',
    defaultMessage: 'Impostazioni Link',
  },
  LinkTo: {
    id: 'Link to',
    defaultMessage: 'Link a',
  },
  LinkToTitle: {
    id: 'Link title',
    defaultMessage: 'Titolo link',
  },
  openLinkInNewTab: {
    id: 'Open in a new tab',
    defaultMessage: 'Apri in un nuovo tab',
  },
  showArrowOnLink: {
    id: 'Show arrow on button',
    defaultMessage: 'Mostra la freccia sul bottone',
  },
  Settings: {
    id: 'Settings',
    defaultMessage: 'Impostazioni',
  },
  NoImageSelected: {
    id: 'No image selected',
    defaultMessage: 'Nessuna immagine selezionata',
  },
  externalURL: {
    id: 'External URL',
    defaultMessage: 'URL esterno',
  },
  size: {
    id: 'Size',
    defaultMessage: 'Dimensione',
  },
  sizeBig: {
    id: 'Size Big',
    defaultMessage: 'Grande',
  },
  sizeSmall: {
    id: 'Size Small',
    defaultMessage: 'Piccola',
  },
  sizeAuto: {
    id: 'Size Auto',
    defaultMessage: 'Auto',
  },

  addOverlay: {
    id: 'Add overlay',
    defaultMessage: "Scurisci l'immagine",
  },

  red: {
    id: 'Red',
    defaultMessage: 'Rosso',
  },
  brown: {
    id: 'Brown',
    defaultMessage: 'Marrone',
  },
});

const Sidebar = ({
  data,
  block,
  onChangeBlock,
  openObjectBrowser,
  required = false,
  intl,
}) => {
  const [activeAccIndex, setActiveAccIndex] = useState(0);

  function handleAccClick(e, titleProps) {
    const { index } = titleProps;
    const newIndex = activeAccIndex === index ? -1 : index;

    setActiveAccIndex(newIndex);
  }
  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>{intl.formatMessage(messages.Image)}</h2>
      </header>

      {!data.url && (
        <>
          <Segment className="sidebar-metadata-container">
            {intl.formatMessage(messages.NoImageSelected)}
            <Icon name={imageSVG} size="100px" color="#b8c6c8" />
          </Segment>
        </>
      )}
      {data.url && (
        <>
          <Segment className="sidebar-metadata-container">
            {data.url.split('/').slice(-1)[0]}
            {data.url.includes(settings.apiPath) && (
              <img
                src={`${flattenToAppURL(data.url)}/@@images/image/mini`}
                alt={data.alt}
              />
            )}
            {!data.url.includes(settings.apiPath) && (
              <img src={data.url} alt={data.alt} style={{ width: '50%' }} />
            )}
          </Segment>
          <Segment className="form sidebar-image-data">
            {data.url.includes(settings.apiPath) && (
              <TextWidget
                id="Origin"
                title={intl.formatMessage(messages.Origin)}
                required={false}
                value={data.url.split('/').slice(-1)[0]}
                icon={navTreeSVG}
                iconAction={() => openObjectBrowser()}
                onChange={() => {}}
              />
            )}
            {!data.url.includes(settings.apiPath) && (
              <TextWidget
                id="external"
                title={intl.formatMessage(messages.externalURL)}
                required={false}
                value={data.url}
                icon={clearSVG}
                iconAction={() =>
                  onChangeBlock(block, {
                    ...data,
                    url: '',
                  })
                }
                onChange={() => {}}
              />
            )}
          </Segment>
          <Accordion fluid styled className="form">
            <Accordion.Title
              active={activeAccIndex === 0}
              index={0}
              onClick={handleAccClick}
            >
              {intl.formatMessage(messages.Settings)}
              {activeAccIndex === 0 ? (
                <Icon name={upSVG} size="20px" />
              ) : (
                <Icon name={downSVG} size="20px" />
              )}
            </Accordion.Title>
            <Accordion.Content active={activeAccIndex === 0}>
              <Form.Field inline required={required}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width="4">
                      <div className="wrapper">
                        <label htmlFor="field-align">
                          {intl.formatMessage(messages.size)}
                        </label>
                      </div>
                    </Grid.Column>
                    <Grid.Column width="8" className="align-tools size-tools">
                      <div>
                        <Button.Group>
                          <Button
                            basic
                            icon
                            onClick={(name, value) => {
                              onChangeBlock(block, {
                                ...data,
                                size: 'big',
                              });
                            }}
                            active={data.size === 'big'}
                            content={intl.formatMessage(messages.sizeBig)}
                          />

                          <Button
                            basic
                            icon
                            aria-label="Small"
                            onClick={(name, value) => {
                              onChangeBlock(block, {
                                ...data,
                                size: 'small',
                              });
                            }}
                            active={data.size === 'small'}
                            content={intl.formatMessage(messages.sizeSmall)}
                          />

                          <Button
                            basic
                            icon
                            aria-label="Auto"
                            onClick={(name, value) => {
                              onChangeBlock(block, {
                                ...data,
                                size: 'auto',
                              });
                            }}
                            active={data.size === 'auto'}
                            content={intl.formatMessage(messages.sizeAuto)}
                          />
                        </Button.Group>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <CheckboxWidget
                        id="addOverlay"
                        title={intl.formatMessage(messages.addOverlay)}
                        value={data.overlay ? data.overlay : false}
                        onChange={(name, value) => {
                          onChangeBlock(block, {
                            ...data,
                            overlay: value,
                          });
                        }}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form.Field>
            </Accordion.Content>
            <Accordion.Title
              active={activeAccIndex === 1}
              index={1}
              onClick={handleAccClick}
            >
              {intl.formatMessage(messages.LinkSettings)}
              {activeAccIndex === 1 ? (
                <Icon name={upSVG} size="20px" />
              ) : (
                <Icon name={downSVG} size="20px" />
              )}
            </Accordion.Title>
            <Accordion.Content active={activeAccIndex === 1}>
              <TextWidget
                id="link"
                title={intl.formatMessage(messages.LinkTo)}
                required={false}
                value={data.href}
                icon={data.href ? clearSVG : navTreeSVG}
                iconAction={
                  data.href
                    ? () => {
                        onChangeBlock(block, {
                          ...data,
                          href: '',
                        });
                      }
                    : () => openObjectBrowser({ mode: 'link' })
                }
                onChange={(name, value) => {
                  onChangeBlock(block, {
                    ...data,
                    href: value,
                  });
                }}
              />

              <TextWidget
                id="linkText"
                title={intl.formatMessage(messages.LinkToTitle)}
                required={false}
                value={data.linkTitle}
                onChange={(name, value) => {
                  onChangeBlock(block, {
                    ...data,
                    linkTitle: value,
                  });
                }}
              />

              <CheckboxWidget
                id="openLinkInNewTab"
                title={intl.formatMessage(messages.openLinkInNewTab)}
                value={data.openLinkInNewTab ? data.openLinkInNewTab : false}
                onChange={(name, value) => {
                  onChangeBlock(block, {
                    ...data,
                    openLinkInNewTab: value,
                  });
                }}
              />

              <CheckboxWidget
                id="showArrow"
                title={intl.formatMessage(messages.showArrowOnLink)}
                value={data.showArrowOnLink ? data.showArrowOnLink : false}
                onChange={(name, value) => {
                  onChangeBlock(block, {
                    ...data,
                    showArrowOnLink: value,
                  });
                }}
              />
            </Accordion.Content>
          </Accordion>
        </>
      )}
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  openObjectBrowser: PropTypes.func.isRequired,
};

export default injectIntl(Sidebar);
