/**
 * Add your actions here.
 * @module actions
 * @example
 * import {
 *   searchContent,
 * } from './search/search';
 *
 * export {
 *   searchContent,
 * };
 */

export const SEND_DATA = 'SEND_DATA';
export const GET_KEYWORD_COLORS = 'GET_KEYWORD_COLORS';

export const sendData = (url, payload) => {
  const request = {
    op: 'post',
    path: url,
    data: payload,
    type: 'application/json',
    headers: {
      Accept: 'application/json',
    },
  };
  console.log('sending data to Plone: ', request, SEND_DATA);
  return { type: SEND_DATA, request };
};

/**
 * Get getKeywordColors mapping 
 * @function getKeywordColors
 
 */
export function getKeywordColors() {
  // In case we have a URL, we have to get the vocabulary name
  return {
    type: GET_KEYWORD_COLORS,
    request: {
      op: 'get',
      path: `/@keyword-colors`,
    },
  };
}
