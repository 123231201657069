/**
 * Image dropzone widget
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { readAsDataURL } from 'promise-file-reader';

import { Button, Dimmer, Input, Loader, Message } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import { createContent } from '@plone/volto/actions';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { compose } from 'redux';

import imageBlockSVG from './block-image.svg';
import { Icon } from '@plone/volto/components';
import { getBaseUrl } from '@plone/volto/helpers';

import clearSVG from '@plone/volto/icons/clear.svg';
import navTreeSVG from '@plone/volto/icons/nav.svg';
import aheadSVG from '@plone/volto/icons/ahead.svg';
import uploadSVG from '@plone/volto/icons/upload.svg';
import { settings } from '~/config';

const messages = defineMessages({
  ImageBlockInputPlaceholder: {
    id: 'Browse the site, drop an image, or type an URL',
    defaultMessage: "Cerca nel sito, trascina un'immagine, o inserisci un URL",
  },
});

/**
 * ImageDropzoneWidgetclass.
 * @class ImageDropzoneWidget
 * @extends Component
 */
class ImageDropzoneWidget extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    onChangeBlock: PropTypes.func.isRequired,
    selected: PropTypes.bool.isRequired,
    openObjectBrowser: PropTypes.func.isRequired,

    data: PropTypes.objectOf(PropTypes.any).isRequired,
    content: PropTypes.objectOf(PropTypes.any).isRequired,
    request: PropTypes.shape({
      loading: PropTypes.bool,
      loaded: PropTypes.bool,
    }).isRequired,
    pathname: PropTypes.string.isRequired,

    createContent: PropTypes.func.isRequired,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
      url: '',
    };
    if (!__SERVER__) {
      this.state = {
        uploading: false,
        url: props.data.url ? props.data.url : '',
      };
    }
  }

  /**
   * Change url handler
   * @method onChangeUrl
   * @param {Object} target Target object
   * @returns {undefined}
   */
  onChangeUrl = ({ target }) => {
    this.setState({
      url: target.value,
    });
  };

  /**
   * Submit url handler
   * @method onSubmitUrl
   * @param {object} e Event
   * @returns {undefined}
   */
  onSubmitUrl = () => {
    this.props.onChangeBlock('url', this.state.url);
  };

  /**
   * Drop handler
   * @method onDrop
   * @param {array} files File objects
   * @returns {undefined}
   */
  onDrop = file => {
    this.uploadFile(file[0]);
  };

  uploadFile = file => {
    this.setState({
      uploading: true,
    });
    readAsDataURL(file).then(data => {
      const fields = data.match(/^data:(.*);(.*),(.*)$/);

      this.props
        .createContent(getBaseUrl(this.props.pathname), {
          '@type': 'Image',
          title: file.name,
          image: {
            data: fields[3],
            encoding: fields[2],
            'content-type': fields[1],
            filename: file.name,
          },
        })
        .then(data => {
          this.setState({
            uploading: false,
          });

          this.props.onChangeBlock('url', data['@id']);
        });
    });
  };

  /**
   * Upload image handler (not used), but useful in case that we want a button
   * not powered by react-dropzone
   * @method onUploadImage
   * @returns {undefined}
   */
  onUploadImage = ({ target }) => {
    const file = target.files[0];
    this.uploadFile(file);
  };

  /**
   * Keydown handler on Variant Menu Form
   * This is required since the ENTER key is already mapped to a onKeyDown
   * event and needs to be overriden with a child onKeyDown.
   * @method onKeyDownVariantMenuForm
   * @param {Object} e Event object
   * @returns {undefined}
   */
  onKeyDownVariantMenuForm = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      this.onSubmitUrl();
    } else if (e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();
      // TODO: Do something on ESC key
    }
  };

  node = React.createRef();

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <>
        {this.props.selected && !!this.props.data.url && (
          <div className="toolbar">
            {this.props.appendActions && <>{this.props.appendActions}</>}
            {this.props.detached && this.props.appendActions && (
              <div className="separator" />
            )}
            <Button.Group>
              <Button
                icon
                basic
                onClick={() => {
                  this.props.onChangeBlock('url', '');
                  this.setState({ url: '' });
                }}
              >
                <Icon name={clearSVG} size="24px" color="#e40166" />
              </Button>
            </Button.Group>
            {this.props.appendSecondaryActions && (
              <>{this.props.appendSecondaryActions}</>
            )}
          </div>
        )}

        {this.props.selected &&
          !this.props.data.url &&
          this.props.appendSecondaryActions && (
            <div className="toolbar">{this.props.appendSecondaryActions}</div>
          )}

        {this.props.data.url ? (
          <>{this.props.children}</>
        ) : (
          <div>
            <Dropzone disableClick onDrop={this.onDrop} className="dropzone">
              <Message>
                {this.state.uploading && (
                  <Dimmer active>
                    <Loader indeterminate>Uploading image</Loader>
                  </Dimmer>
                )}
                <center>
                  <img src={imageBlockSVG} alt="" />
                  <div className="toolbar-inner">
                    <Button.Group>
                      <Button
                        basic
                        icon
                        onClick={e => {
                          e.stopPropagation();
                          this.props.openObjectBrowser({
                            mode: 'image',
                            onSelectItem: url => {
                              this.props.onChangeBlock(
                                'url',
                                settings.apiPath + url,
                              );
                            },
                          });
                        }}
                      >
                        <Icon name={navTreeSVG} size="24px" />
                      </Button>
                    </Button.Group>
                    <Button.Group>
                      <label className="ui button basic icon">
                        <Icon name={uploadSVG} size="24px" />
                        <input
                          type="file"
                          onChange={this.onUploadImage}
                          style={{ display: 'none' }}
                        />
                      </label>
                    </Button.Group>
                    <Input
                      onKeyDown={this.onKeyDownVariantMenuForm}
                      onChange={this.onChangeUrl}
                      placeholder={this.props.intl.formatMessage(
                        messages.ImageBlockInputPlaceholder,
                      )}
                      value={this.state.url}
                      // Prevents propagation to the Dropzone and the opening
                      // of the upload browser dialog
                      onClick={e => e.stopPropagation()}
                    />

                    {this.state.url && (
                      <Button.Group>
                        <Button
                          basic
                          className="cancel"
                          onClick={e => {
                            e.stopPropagation();
                            this.setState({ url: '' });
                          }}
                        >
                          <Icon name={clearSVG} size="30px" />
                        </Button>
                      </Button.Group>
                    )}
                    <Button.Group>
                      <Button
                        basic
                        primary
                        disabled={!this.state.url}
                        onClick={e => {
                          e.stopPropagation();
                          this.onSubmitUrl();
                        }}
                      >
                        <Icon name={aheadSVG} size="30px" />
                      </Button>
                    </Button.Group>
                  </div>
                </center>
              </Message>
            </Dropzone>
          </div>
        )}
      </>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    state => ({
      request: state.content.create,
      content: state.content.data,
    }),
    { createContent },
  ),
)(ImageDropzoneWidget);
