/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { Container, List, Segment, Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { defineMessages, injectIntl } from 'react-intl';
import { Logo } from '@plone/volto/components';
import cagLogo from '@package/components/Footer/loghi/cag.png';

import comuneModenaLogo from '@package/components/Footer/loghi/comune-modena.png';
import monetLogo from '@package/components/Footer/loghi/monet.png';
import europeDirectLogo from '@package/components/Footer/loghi/eurodM.jpg';
import centroMusicaLogo from '@package/components/Footer/loghi/CentroMusica.jpg';
import laTendaLogo from '@package/components/Footer/loghi/latenda.jpg';
import netGarageLogo from '@package/components/Footer/loghi/reteNet.jpg';
import servizioCivileLogo from '@package/components/Footer/loghi/SCV.jpg';
import makeitLogo from '@package/components/Footer/loghi/makeit.png';
import memoLogo from '@package/components/Footer/loghi/Memo.jpg';
import unioneEuropeaLogo from '@package/components/Footer/loghi/unioneEuropea.png';
import Matomo from '@package/components/Matomo/Matomo';
import { CookieBanner } from 'volto-cookie-banner';

const messages = defineMessages({
  subscribe_newsletter: {
    id: 'Newsletter site subscribe',
    defaultMessage: 'Iscriviti sul sito',
  },
  subscribe_newsletter_cta: {
    id: 'Newsletter site subscribe cta',
    defaultMessage: 'Iscriviti alla newsletter di',
  },
});
/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */

const Footer = ({ intl }) => {
  let newsletter_subscribe_link =
    'https://www.comune.modena.it/argomenti/newsletter';
  let loghi = [
    {
      image: comuneModenaLogo,
      link: 'https://www.comune.modena.it/politichegiovanili',
      title: 'Comune di Modena - Politiche Giovanili',
    },
    {
      image: monetLogo,
      link: 'http://www.comune.modena.it/',
      title: 'Comune di Modena',
    },
    {
      image: laTendaLogo,
      link: 'https://www.comune.modena.it/latenda',
      title: 'La tenda',
    },
    {
      image: europeDirectLogo,
      link: 'https://www.comune.modena.it/europe-direct',
      title: 'Europedirect',
    },
    {
      image: cagLogo,
      link:
        'https://www.comune.modena.it/modenaunder/centri-di-aggregazione-giovanile',
      title: 'Centri di aggregazione giovanile',
    },
    {
      image: servizioCivileLogo,
      link:
        'https://www.comune.modena.it/modenaunder/servizio-civile#serviziocivile',
      title: 'Servizio civile',
    },
    {
      image: netGarageLogo,
      link:
        'https://www.comune.modena.it/modena-smart-community/progetti-2014-2018/net-garage',
      title: 'Net Garage',
    },
    {
      image: makeitLogo,
      link: 'https://www.comune.modena.it/makeitmodena',
      title: 'MakeIt Modena',
    },
    // {
    //   image: modenaUnderLogo,
    //   link: 'https://www.comune.modena.it/modenaunder/',
    //   title: 'Modena Under'
    // },
    {
      image: memoLogo,
      link: 'https://www.comune.modena.it/memo',
      title: 'Memo - Modena',
    },
    {
      image: unioneEuropeaLogo,
      link: 'https://europa.eu/',
      title: 'Unione Europea',
    },
    {
      image: centroMusicaLogo,
      link: 'http://www.musicplus.it/',
      title: 'Musicplus - Centromusica',
    },
  ];

  return (
    <Segment role="contentinfo" vertical className="footer">
      <Matomo />
      <Segment basic padded className="subscribe-newsletter">
        <Container>
          <Grid stackable columns="equal" verticalAlign="middle">
            <Grid.Row>
              <Grid.Column />
              <Grid.Column className="text">
                <a
                  href={newsletter_subscribe_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="vai alla Newsletter Unox1"
                >
                  {intl.formatMessage(messages.subscribe_newsletter_cta)}
                  <h1>Unox1</h1>{' '}
                </a>
              </Grid.Column>
              {/* <Grid.Column textAlign="right">
                <a
                  className="ui button primary"
                  href={newsletter_subscribe_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={intl.formatMessage(messages.subscribe_newsletter)}
                >
                  {intl.formatMessage(messages.subscribe_newsletter)}
                </a>
              </Grid.Column> */}
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
      <Segment vertical className="loghi">
        <Grid
          stackable
          verticalAlign="middle"
          centered
          textAlign="center"
          columns={8}
          container
        >
          <Grid.Row>
            {loghi.map((logo, index) => (
              <Grid.Column textAlign="center" key={'logo' + index}>
                {logo.link ? (
                  <a
                    href={logo.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={logo.title}
                  >
                    <img src={logo.image} alt={logo.title} />
                  </a>
                ) : (
                  <img src={logo.image} alt={logo.title} />
                )}
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
      </Segment>
      <Segment basic padded vertical>
        <Grid container stackable verticalAlign="middle">
          <Grid.Row>
            <Grid.Column width={2} className="site-logo">
              <Logo footer={true} height={100} />
            </Grid.Column>
            <Grid.Column width={3}>
              Il portale web dell'Assessorato alle Politiche Giovanili del
              Comune di Modena
            </Grid.Column>
            <Grid.Column width={7} textAlign="right">
              <List horizontal>
                <div role="listitem" className="item">
                  <Link className="item" to="/chi-siamo" aria-label="Chi Siamo">
                    Chi siamo
                  </Link>
                </div>
                <div role="listitem" className="item">
                  <Link className="item" to="/contatti" aria-label="Contatti">
                    Contatti
                  </Link>
                </div>
                <div role="listitem" className="item">
                  <Link
                    className="item"
                    to="/sitemap"
                    aria-label="Mappa del sito"
                  >
                    Mappa del sito
                  </Link>
                </div>
                <div role="listitem" className="item">
                  <Link className="item" to="/privacy" aria-label="Privacy">
                    Privacy
                  </Link>
                </div>
                <div role="listitem" className="item">
                  <Link
                    className="item"
                    to="/dichiarazione-di-accessibilita"
                    aria-label="Dichiarazione di accessibilità"
                  >
                    Dichiarazione di accessibilità
                  </Link>
                </div>
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      {__CLIENT__ && <CookieBanner />}
    </Segment>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
