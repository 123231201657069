/**
 * View icons block.
 * @module components/manage/Blocks/IconsBlocks/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import redraft from 'redraft';
import ViewBlock from './Block/ViewBlock';
import { Menu } from 'semantic-ui-react';
/**
 * View icons blocks class.
 * @class View
 * @extends Component
 */
const View = ({ data }) => {
  return (
    <div className="block iconsBlocks">
      <Menu vertical secondary>
        {data.title && (
          <Menu.Item header>
            <h2 className="plaster">{redraft(data.title)}</h2>
          </Menu.Item>
        )}

        {data.subblocks &&
          data.subblocks.map((subblock, index) => (
            <ViewBlock data={subblock} key={subblock.id} />
          ))}
      </Menu>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
