import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import { Icon, TextWidget } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';

import { settings } from '~/config';

import imageSVG from '@plone/volto/icons/image.svg';
import clearSVG from '@plone/volto/icons/clear.svg';
import navTreeSVG from '@plone/volto/icons/nav.svg';

import { LinkToWidget } from '@package/components/';

const messages = defineMessages({
  Origin: {
    id: 'Origin',
    defaultMessage: 'Origine',
  },

  LinkSettings: {
    id: 'Link settings',
    defaultMessage: 'Impostazioni Link',
  },
  LinkToTitle: {
    id: 'Link title',
    defaultMessage: 'Titolo link',
  },
  NoImageSelected: {
    id: 'No image selected',
    defaultMessage: 'Nessuna immagine selezionata',
  },
  externalURL: {
    id: 'External URL',
    defaultMessage: 'URL esterno',
  },
});

const ImageSettings = ({ data, onChange, openObjectBrowser, intl }) => {
  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage id="Image" defaultMessage="Image" />
        </h2>
      </header>
      {!data.url && (
        <>
          <Segment className="sidebar-metadata-container">
            {intl.formatMessage(messages.NoImageSelected)}
            <Icon name={imageSVG} size="100px" color="#b8c6c8" />
          </Segment>
        </>
      )}
      {data.url && (
        <>
          <Segment className="sidebar-metadata-container">
            {data.url.split('/').slice(-1)[0]}
            {data.url.includes(settings.apiPath) && (
              <img
                src={`${flattenToAppURL(data.url)}/@@images/image/mini`}
                alt={data.alt}
              />
            )}
            {!data.url.includes(settings.apiPath) && (
              <img src={data.url} alt={data.alt} style={{ width: '50%' }} />
            )}
          </Segment>
          <Segment className="form sidebar-image-data">
            {data.url.includes(settings.apiPath) && (
              <TextWidget
                id="Origin"
                title={intl.formatMessage(messages.Origin)}
                required={false}
                value={data.url.split('/').slice(-1)[0]}
                icon={navTreeSVG}
                iconAction={() =>
                  openObjectBrowser({
                    mode: 'image',
                    onSelectItem: url =>
                      onChange('url', settings.apiPath + url),
                  })
                }
                onChange={() => {}}
              />
            )}
            {!data.url.includes(settings.apiPath) && (
              <TextWidget
                id="external"
                title={intl.formatMessage(messages.externalURL)}
                required={false}
                value={data.url}
                icon={clearSVG}
                iconAction={() => onChange('url', '')}
                onChange={() => {}}
              />
            )}
          </Segment>

          <header className="header pulled">
            <h2>{intl.formatMessage(messages.LinkSettings)}</h2>
          </header>
          <Segment>
            <LinkToWidget
              data={data}
              showTitle={false}
              openObjectBrowser={openObjectBrowser}
              onChange={onChange}
            />
          </Segment>
        </>
      )}
    </Segment.Group>
  );
};

ImageSettings.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
  openObjectBrowser: PropTypes.func.isRequired,
};

export default injectIntl(ImageSettings);
