import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import cx from 'classnames';

import { ButtonLink, ItemTemplates } from '@package/components';

const GrigliaTemplate = ({
  items,
  isEditMode,
  title,
  titleColor,
  linkMore,
  align,
}) => {
  let _items = items;

  let firstItem = _items[0];
  let FirstItemTemplate =
    ItemTemplates[firstItem['@type']] || ItemTemplates.Default;
  let otherItems = _items.slice(1);

  return (
    <div className={cx('grid-template', align)}>
      {title && <h2 className={cx('square', titleColor)}>{title}</h2>}
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={5} className="listing-item first-item">
            <FirstItemTemplate item={firstItem} description={true} />
          </Grid.Column>
          <Grid.Column width={7}>
            <Grid stackable>
              <Grid.Row>
                {otherItems.map((item, index) => {
                  let ItemTemplate =
                    ItemTemplates[item['@type']] || ItemTemplates.Default;
                  return (
                    <Grid.Column
                      className="listing-item"
                      key={item['@id']}
                      width={6}
                    >
                      <ItemTemplate item={item} isEditMode={isEditMode} />
                    </Grid.Column>
                  );
                })}
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {linkMore?.href && (
        <div className="bottom">
          <ButtonLink
            to={linkMore.href}
            title={linkMore.title}
            color="primary"
            aria-label={linkMore.title}
          />
        </div>
      )}
    </div>
  );
};

GrigliaTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default injectIntl(GrigliaTemplate);
