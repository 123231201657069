/**
 * ButtonLink block.
 * @module components/Blocks/ButtonLink/ButtonLink
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { settings } from '~/config';
import cx from 'classnames';
import rightArrowSVG from './arrow-link.svg';

/**
 * ButtonLink image block class.
 * @class ButtonLink
 * @extends Component
 */
const ButtonLink = ({
  to,
  title,
  targetBlank,
  showArrow,
  color,
  _className,
}) => {
  let link = null;
  if (to) {
    let text = <>{title ? title : to}</>;

    if (!color) {
      color = 'yellow';
    }

    const isReallyExternal =
      (to.startsWith('http') || to.startsWith('https')) &&
      !to.includes(settings.apiPath);
    if (isReallyExternal) {
      link = (
        <a
          className={cx('ui button', color, _className)}
          target={targetBlank ? '_blank' : null}
          href={to}
          aria-label={text}
        >
          {text}
          {showArrow && <img src={rightArrowSVG} className="icon" alt="" />}
        </a>
      );
    } else {
      link = (
        <Link
          className={cx('ui button', color, _className)}
          color={color}
          to={to.replace(settings.apiPath, '')}
          target={targetBlank ? '_blank' : null}
          aria-label={text}
        >
          {text}
          {showArrow && <img src={rightArrowSVG} className="icon" alt="" />}
        </Link>
      );
    }
  }

  return link;
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ButtonLink.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
  targetBlank: PropTypes.bool,
  showArrow: PropTypes.bool,
  color: PropTypes.string,
  _className: PropTypes.string,
};

export default ButtonLink;
