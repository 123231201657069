/**
 * ViewBlock.
 * @module components/manage/Blocks/Text/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import redraft from 'redraft';
import { Link } from 'react-router-dom';
import { settings } from '~/config';
import ViewIcon from './ViewIcon';
import { Menu } from 'semantic-ui-react';
import cx from 'classnames';
import { ButtonLink } from '@package/components';

/**
 * ViewBlock class.
 * @class ViewBlock
 * @extends Component
 */
const ViewBlock = ({ data }) => {
  let content = (
    <>
      <ViewIcon icon={data.icon} size="40px" />
      {data.description && (
        <div className="description">{redraft(data.description)}</div>
      )}
    </>
  );

  if (data.with_button) {
    content = (
      <Menu.Item className="with_button">
        {content}
        <ButtonLink
          _className="primary"
          to={data.href}
          title={data.text_on_button}
          target={data.openLinkInNewTab}
          aria-label={data.text_on_button}
        />
      </Menu.Item>
    );
  } else if (data.href) {
    const isReallyExternal =
      (data.href.startsWith('http') || data.href.startsWith('https')) &&
      !data.href.includes(settings.apiPath);

    const cls = cx('item', data.with_button ? 'with_button' : null);

    if (isReallyExternal) {
      content = (
        <a
          target={data.openLinkInNewTab ? '_blank' : null}
          href={data.href}
          className={cls}
          aria-label={data.href}
        >
          {content}
        </a>
      );
    } else {
      content = (
        <Link
          className={cls}
          to={data.href.replace(settings.apiPath, '')}
          target={data.openLinkInNewTab ? '_blank' : null}
          aria-label={data.href}
        >
          {content}
        </Link>
      );
    }
  } else {
    content = <Menu.Item>{content}</Menu.Item>;
  }

  return content;
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ViewBlock.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ViewBlock;
