/**
 * View image block.
 * @module components/Blocks/FullImage/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import redraft from 'redraft';
import { settings } from '~/config';
import { Grid } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import { ButtonLink } from '~/components';
import { Link } from 'react-router-dom';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';

/**
 * View image block class.
 * @class View
 * @extends Component
 */
const View = ({ data, detached }) => {
  const wrapLink = (href, content, alt) => {
    let ret = href ? (
      isInternalURL(href) ? (
        <Link to={href} aria-label={alt}>
          {content}
        </Link>
      ) : (
        <a
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={alt}
        >
          {content}
        </a>
      )
    ) : (
      content
    );
    return ret;
  };

  let block1 = data.block1 ? (
    <div className="big-map">
      {data.block1.url && (
        <img
          alt={data.block1.title}
          src={
            data.block1.url.includes(settings.apiPath)
              ? `${flattenToAppURL(data.block1.url)}/@@images/image`
              : data.block1.url
          }
        />
      )}
      {data.block1.title && (
        <div className="title">
          {data.block1.link && (
            <ButtonLink to={data.block1.link} text={data.block1.title} />
          )}
          {!data.block1.link ? data.block1.title : ''}
        </div>
      )}
    </div>
  ) : null;

  block1 = wrapLink(data.block1?.href, block1, data.block1.title);

  let block2 = data.block2 ? (
    <div className="block">
      {data.block2.url && (
        <img
          alt={data.block2.title}
          src={
            data.block2.url.includes(settings.apiPath)
              ? `${flattenToAppURL(data.block2.url)}/@@images/image`
              : data.block2.url
          }
        />
      )}
      {data.block2.title && <div className="title">{data.block2.title}</div>}
      {data.block2.description && (
        <div className="description">{data.block2.description}</div>
      )}
    </div>
  ) : null;

  block2 = wrapLink(data.block2?.href, block2, data.block2.title);

  let block3 = data.block3 ? (
    <div className="block">
      {data.block3.url && (
        <img
          alt={data.block3.description}
          src={
            data.block3.url.includes(settings.apiPath)
              ? `${flattenToAppURL(data.block3.url)}/@@images/image`
              : data.block3.url
          }
        />
      )}
      {data.block3.description && (
        <div className="description">{data.block3.description}</div>
      )}
    </div>
  ) : null;

  block3 = wrapLink(data.block3?.href, block3, data.block3.title);

  const col1 = block1 && (
    <Grid.Column tablet={16} computer={8} className="col-left">
      {block1}
    </Grid.Column>
  );
  const col2 = (block2 || block3) && (
    <Grid.Column tablet={16} computer={4} className="col-right">
      {block2} {block3}
    </Grid.Column>
  );

  return col1 || col2 ? (
    <div className="block youngER">
      {data.title && <h2 className="plaster">{redraft(data.title)}</h2>}

      <Grid stackable>
        <Grid.Row stretched>
          {col1}
          {col2}
        </Grid.Row>
      </Grid>
    </div>
  ) : null;
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
