import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { CheckboxWidget } from '@plone/volto/components';
import redraft from 'redraft';
import { settings } from '~/config';
import { IconsWidget, LinkToWidget } from '@package/components';
import IconList from '../icons';

const messages = defineMessages({
  with_button: {
    id: 'with_button',
    defaultMessage: 'Con bottone',
  },
});

const SidebarBlock = ({
  data,
  block,
  onChangeBlock,
  openObjectBrowser,
  required = false,
  intl,
}) => {
  let blockTitle = data.description
    ? redraft(
        data.description,
        settings.ToHTMLRenderers,
        settings.ToHTMLOptions,
      )
    : null;

  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage id="Subblock" defaultMessage="Sottoblocco" />:
          {blockTitle}
        </h2>
      </header>

      <>
        <Segment>
          <LinkToWidget
            data={data}
            openObjectBrowser={openObjectBrowser}
            onChange={(name, value) => {
              onChangeBlock(data.id, {
                ...data,
                [name]: value,
              });
            }}
          />
        </Segment>
        <Segment>
          <CheckboxWidget
            id="with_button"
            title={intl.formatMessage(messages.with_button)}
            value={data.with_button ? data.with_button : false}
            onChange={(name, value) => {
              onChangeBlock(data.id, {
                ...data,
                [name]: value,
              });
            }}
          />
        </Segment>
        <IconsWidget
          value={data.icon}
          icons={IconList}
          onChange={icon => {
            onChangeBlock(data.id, {
              ...data,
              icon: icon,
            });
          }}
        />
      </>
    </Segment.Group>
  );
};

SidebarBlock.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  openObjectBrowser: PropTypes.func.isRequired,
};

export default injectIntl(SidebarBlock);
