/**
 * Social infos actions.
 * @module actions/socialInfos/getSocialInfos
 */
export const GET_SOCIAL_INFOS = 'GET_SOCIAL_INFOS';

/**
 * Get social infos.
 * @function getSocialInfos
 * @returns {Object} Get social infos action.
 */
export function getSocialInfos() {
  return {
    type: GET_SOCIAL_INFOS,
    request: {
      op: 'get',
      path: `/@social-infos`,
    },
  };
}
