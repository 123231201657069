/**
 * Edit image block.
 * @module components/Blocks/FullImage/Edit
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { readAsDataURL } from 'promise-file-reader';
import { Map } from 'immutable';

import {
  Button,
  Dimmer,
  Input,
  Loader,
  Message,
  Container,
} from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import cx from 'classnames';
import Dropzone from 'react-dropzone';
import { Editor, DefaultDraftBlockRenderMap, EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { settings } from '~/config';

import { Icon, SidebarPortal } from '@plone/volto/components';
import { createContent } from '@plone/volto/actions';
import { flattenToAppURL, getBaseUrl } from '@plone/volto/helpers';
import withObjectBrowser from '@plone/volto/components/manage/Sidebar/ObjectBrowser';

import imageBlockSVG from './block-image.svg';
import clearSVG from '@plone/volto/icons/clear.svg';
import navTreeSVG from '@plone/volto/icons/nav.svg';
import aheadSVG from '@plone/volto/icons/ahead.svg';

import Sidebar from './Sidebar';
import { ButtonLink } from '~/components';

const messages = defineMessages({
  ImageBlockInputPlaceholder: {
    id: 'Browse the site, drop an image, or type an URL',
    defaultMessage: "Cerca nel sito, trascina un'immagine, o inserisci un URL",
  },
  title: {
    id: 'Title',
    defaultMessage: 'Titolo',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Descrizione',
  },
});

const blockTitleRenderMap = Map({
  unstyled: {
    element: 'div',
  },
});

const blockDescriptionRenderMap = Map({
  unstyled: {
    element: 'div',
  },
});

const extendedTitleBlockRenderMap = DefaultDraftBlockRenderMap.merge(
  blockTitleRenderMap,
);

const extendedDescripBlockRenderMap = DefaultDraftBlockRenderMap.merge(
  blockDescriptionRenderMap,
);

/**
 * Edit image block class.
 * @class Edit
 * @extends Component
 */
class Edit extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    selected: PropTypes.bool.isRequired,
    block: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    content: PropTypes.objectOf(PropTypes.any).isRequired,
    request: PropTypes.shape({
      loading: PropTypes.bool,
      loaded: PropTypes.bool,
    }).isRequired,
    pathname: PropTypes.string.isRequired,
    onChangeBlock: PropTypes.func.isRequired,
    onSelectBlock: PropTypes.func.isRequired,
    onDeleteBlock: PropTypes.func.isRequired,
    onFocusPreviousBlock: PropTypes.func.isRequired,
    onFocusNextBlock: PropTypes.func.isRequired,
    handleKeyDown: PropTypes.func.isRequired,
    createContent: PropTypes.func.isRequired,
    openObjectBrowser: PropTypes.func.isRequired,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
      url: '',
    };
    if (!__SERVER__) {
      if (!props.data.align) {
        props.data.align = 'full'; //default
      }
      if (!props.data.size) {
        props.data.size = 'small'; //default
      }
      if (!this.props.data.link) {
        this.props.data.link = {};
      }
      let titleEditorState;
      let descriptionEditorState;
      if (props.data && props.data.title) {
        titleEditorState = EditorState.createWithContent(
          stateFromHTML(props.data.title),
        );
      } else {
        titleEditorState = EditorState.createEmpty();
      }
      if (props.data && props.data.description) {
        descriptionEditorState = EditorState.createWithContent(
          stateFromHTML(props.data.description),
        );
      } else {
        descriptionEditorState = EditorState.createEmpty();
      }

      this.state = {
        uploading: false,
        url: props.data.url ? props.data.url : '',
        titleEditorState,
        descriptionEditorState,
        currentFocused: 'title',
      };
    }

    this.onUploadImage = this.onUploadImage.bind(this);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.request.loading &&
      nextProps.request.loaded &&
      this.state.uploading
    ) {
      this.setState({
        uploading: false,
      });
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        url: nextProps.content['@id'],
      });
    }

    if (
      nextProps.data.title &&
      this.props.data.title !== nextProps.data.title &&
      !this.props.selected
    ) {
      const contentState = stateFromHTML(nextProps.data.title);
      this.setState({
        titleEditorState: nextProps.data.title
          ? EditorState.createWithContent(contentState)
          : EditorState.createEmpty(),
      });
    }

    if (
      nextProps.data.description &&
      this.props.data.description !== nextProps.data.description &&
      !this.props.selected
    ) {
      const contentState = stateFromHTML(nextProps.data.description);
      this.setState({
        descriptionEditorState: nextProps.data.description
          ? EditorState.createWithContent(contentState)
          : EditorState.createEmpty(),
      });
    }

    if (nextProps.selected !== this.props.selected) {
      if (this.state.currentFocused === 'title') {
        this.titleEditor.focus();
      } else {
        this.descriptionEditor.focus();
      }
    }
  }

  /**
   * Change Title handler
   * @method onChangeTitle
   * @param {object} titleEditorState Editor state.
   * @returns {undefined}
   */
  onChangeTitle = titleEditorState => {
    this.setState({ titleEditorState }, () => {
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        title: titleEditorState.getCurrentContent().getPlainText(),
      });
    });
  };

  /**
   * Change Description handler
   * @method onChangeDescription
   * @param {object} descriptionEditorState Editor state.
   * @returns {undefined}
   */
  onChangeDescription = descriptionEditorState => {
    this.setState({ descriptionEditorState }, () => {
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        description: descriptionEditorState.getCurrentContent().getPlainText(),
      });
    });
  };

  /**
   * Upload image handler (not used), but useful in case that we want a button
   * not powered by react-dropzone
   * @method onUploadImage
   * @returns {undefined}
   */
  onUploadImage = ({ target }) => {
    const file = target.files[0];
    this.setState({
      uploading: true,
    });
    readAsDataURL(file).then(data => {
      const fields = data.match(/^data:(.*);(.*),(.*)$/);
      this.props.createContent(getBaseUrl(this.props.pathname), {
        '@type': 'Image',
        title: file.name,
        image: {
          data: fields[3],
          encoding: fields[2],
          'content-type': fields[1],
          filename: file.name,
        },
      });
    });
  };

  /**
   * Change url handler
   * @method onChangeUrl
   * @param {Object} target Target object
   * @returns {undefined}
   */
  onChangeUrl = ({ target }) => {
    this.setState({
      url: target.value,
    });
  };

  /**
   * Submit url handler
   * @method onSubmitUrl
   * @param {object} e Event
   * @returns {undefined}
   */
  onSubmitUrl = () => {
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      url: this.state.url,
    });
  };

  /**
   * Drop handler
   * @method onDrop
   * @param {array} files File objects
   * @returns {undefined}
   */
  onDrop = file => {
    this.setState({
      uploading: true,
    });

    readAsDataURL(file[0]).then(data => {
      const fields = data.match(/^data:(.*);(.*),(.*)$/);
      this.props.createContent(getBaseUrl(this.props.pathname), {
        '@type': 'Image',
        title: file[0].name,
        image: {
          data: fields[3],
          encoding: fields[2],
          'content-type': fields[1],
          filename: file[0].name,
        },
      });
    });
  };

  /**
   * Keydown handler on Variant Menu Form
   * This is required since the ENTER key is already mapped to a onKeyDown
   * event and needs to be overriden with a child onKeyDown.
   * @method onKeyDownVariantMenuForm
   * @param {Object} e Event object
   * @returns {undefined}
   */
  onKeyDownVariantMenuForm = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      this.onSubmitUrl();
    } else if (e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();
      // TODO: Do something on ESC key
    }
  };

  node = React.createRef();

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <div className={cx('block')}>
        {this.props.selected && !!this.props.data.url && (
          <div className="toolbar">
            {this.props.appendActions && <>{this.props.appendActions}</>}
            {this.props.detached && this.props.appendActions && (
              <div className="separator" />
            )}
            <Button.Group>
              <Button
                icon
                basic
                onClick={() =>
                  this.props.onChangeBlock(this.props.block, {
                    ...this.props.data,
                    url: '',
                  })
                }
              >
                <Icon name={clearSVG} size="24px" color="#e40166" />
              </Button>
            </Button.Group>
            {this.props.appendSecondaryActions && (
              <>{this.props.appendSecondaryActions}</>
            )}
          </div>
        )}
        {this.props.selected &&
          !this.props.data.url &&
          this.props.appendSecondaryActions && (
            <div className="toolbar">{this.props.appendSecondaryActions}</div>
          )}
        {this.props.data.url ? (
          <>
            <div
              className={cx(
                'content',
                {
                  /*
                  'full-width': this.props.data.align === 'full',
                */
                },
                this.props.data.size,
              )}
            >
              <img
                alt={this.props.data.title}
                src={
                  this.props.data.url.includes(settings.apiPath)
                    ? `${flattenToAppURL(this.props.data.url)}/@@images/image`
                    : this.props.data.url
                }
                className="bg"
              />
              {this.props.data.overlay && <div className="overlay" />}
              <Container className="overlay-text">
                <div className="title">
                  <Editor
                    ref={node => {
                      this.titleEditor = node;
                    }}
                    onChange={this.onChangeTitle}
                    editorState={this.state.titleEditorState}
                    blockRenderMap={extendedTitleBlockRenderMap}
                    handleReturn={() => true}
                    placeholder={this.props.intl.formatMessage(messages.title)}
                    blockStyleFn={() => 'title-editor title'}
                    onUpArrow={() => {
                      const selectionState = this.state.titleEditorState.getSelection();
                      const { titleEditorState } = this.state;
                      if (
                        titleEditorState
                          .getCurrentContent()
                          .getBlockMap()
                          .first()
                          .getKey() === selectionState.getFocusKey()
                      ) {
                        this.props.onFocusPreviousBlock(
                          this.props.block,
                          this.props.blockNode.current,
                        );
                      }
                    }}
                    onDownArrow={() => {
                      const selectionState = this.state.titleEditorState.getSelection();
                      const { titleEditorState } = this.state;
                      if (
                        titleEditorState
                          .getCurrentContent()
                          .getBlockMap()
                          .last()
                          .getKey() === selectionState.getFocusKey()
                      ) {
                        this.setState(() => ({
                          currentFocused: 'description',
                        }));
                        this.descriptionEditor.focus();
                      }
                    }}
                  />
                </div>
                <div className="description">
                  <Editor
                    ref={node => {
                      this.descriptionEditor = node;
                    }}
                    onChange={this.onChangeDescription}
                    editorState={this.state.descriptionEditorState}
                    blockRenderMap={extendedDescripBlockRenderMap}
                    handleReturn={() => true}
                    placeholder={this.props.intl.formatMessage(
                      messages.description,
                    )}
                    blockStyleFn={() => 'description-editor description'}
                    onUpArrow={() => {
                      const selectionState = this.state.descriptionEditorState.getSelection();
                      const currentCursorPosition = selectionState.getStartOffset();

                      if (currentCursorPosition === 0) {
                        this.setState(() => ({ currentFocused: 'title' }));
                        this.titleEditor.focus();
                      }
                    }}
                    onDownArrow={() => {
                      const selectionState = this.state.descriptionEditorState.getSelection();
                      const { descriptionEditorState } = this.state;
                      const currentCursorPosition = selectionState.getStartOffset();
                      const blockLength = descriptionEditorState
                        .getCurrentContent()
                        .getFirstBlock()
                        .getLength();

                      if (currentCursorPosition === blockLength) {
                        this.props.onFocusNextBlock(
                          this.props.block,
                          this.props.blockNode.current,
                        );
                      }
                    }}
                  />
                </div>
                <ButtonLink
                  to={this.props.data.href}
                  title={this.props.data.linkTitle}
                  targetBlank={this.props.data.openLinkInNewTab}
                  showArrow={this.props.data.showArrowOnLink}
                  _className="basic"
                />
              </Container>
            </div>
          </>
        ) : (
          <div>
            <Dropzone onDrop={this.onDrop} className="dropzone">
              <Message>
                {this.state.uploading && (
                  <Dimmer active>
                    <Loader indeterminate>Uploading image</Loader>
                  </Dimmer>
                )}
                <center>
                  <img src={imageBlockSVG} alt="" />
                  <div className="toolbar-inner">
                    <Button.Group>
                      <Button
                        basic
                        icon
                        onClick={e => {
                          e.stopPropagation();
                          this.props.openObjectBrowser();
                        }}
                      >
                        <Icon name={navTreeSVG} size="24px" />
                      </Button>
                    </Button.Group>
                    <Input
                      onKeyDown={this.onKeyDownVariantMenuForm}
                      onChange={this.onChangeUrl}
                      placeholder={this.props.intl.formatMessage(
                        messages.ImageBlockInputPlaceholder,
                      )}
                      // Prevents propagation to the Dropzone and the opening
                      // of the upload browser dialog
                      onClick={e => e.stopPropagation()}
                    />
                    {this.state.url && (
                      <Button.Group>
                        <Button basic className="cancel">
                          <Icon name={clearSVG} size="30px" />
                        </Button>
                      </Button.Group>
                    )}
                    <Button.Group>
                      <Button basic primary>
                        <Icon name={aheadSVG} size="30px" />
                      </Button>
                    </Button.Group>
                  </div>
                </center>
              </Message>
            </Dropzone>
          </div>
        )}
        <SidebarPortal selected={this.props.selected}>
          <Sidebar {...this.props} />
        </SidebarPortal>
      </div>
    );
  }
}

export default compose(
  withObjectBrowser,
  injectIntl,
  connect(
    state => ({
      request: state.content.create,
      content: state.content.data,
    }),
    { createContent },
  ),
)(Edit);
