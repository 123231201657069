/**
 * GamblingForm component.
 * @module components/GamblingForm/GamblingForm
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { sendData } from '../../actions';
import { Button, Grid } from 'semantic-ui-react';

const mapStateToProps = state => {
  const { error, results, hasError, loadingResults } = state.gamblingForm;
  return {
    error,
    hasError,
    results,
    loadingResults,
  };
};
const mapDispatchToProps = { sendData };

const options = {
  0: 'Mai',
  1: 'Talvolta',
  2: 'La maggior parte delle volte',
  3: 'Quasi sempre',
};

const questions = [
  {
    text:
      'Avere scommesso più di quanto potessi realmente permetterti di perdere?',
    options: options,
  },
  {
    text:
      'Avere avuto bisogno di giocare più soldi per ottenere la stessa sensazione di eccitazione?',
    options: options,
  },
  {
    text:
      'Essere tornato a giocare con il proposito di recuperare il denaro perso, per “rifarti”?',
    options: options,
  },
  {
    text:
      'Avere chiesto prestiti o aver venduto qualcosa per avere i soldi da giocare?',
    options: options,
  },
  {
    text: 'Avere pensato di avere un problema con il gioco d’azzardo?',
    options: options,
  },
  {
    text:
      'Aver pensato che il gioco ti abbia causato problemi di salute, inclusi stress e ansia?',
    options: options,
  },
  {
    text:
      'Alcune persone ti hanno criticato per il tuo modo di giocare o ti hanno accusato di avere un problema di gioco, a prescindere dal fatto che tu ritenga che ciò sia vero o meno?',
    options: options,
  },
  {
    text:
      'Aver pensato che il gioco ti abbia causato problemi economici o ne abbia causati al tuo bilancio familiare?',
    options: options,
  },
  {
    text:
      'Esserti sentito in colpa per il tuo modo di giocare o per ciò che accade quando giochi?',
    options: options,
  },
];

const GamblingForm = ({
  location,
  error,
  hasError,
  results,
  sendData,
  loadingResults,
}) => {
  const [selectedValues, setSelectedValues] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleClick = (questionIndex, value) => {
    const oldValue = !!selectedValues[questionIndex]
      ? selectedValues[questionIndex]
      : null;
    if (oldValue !== value) {
      setSelectedValues({
        ...selectedValues,
        [questionIndex]: value,
      });
    } else {
      setSelectedValues({
        ...selectedValues,
        [questionIndex]: null,
      });
    }
  };

  const onSubmit = () => {
    const url = `${location.pathname}/@cpgi-gambling-form`;
    let formData = {};
    setFormSubmitted(true);
    for (const key in selectedValues) {
      formData = {
        ...formData,
        [key]: selectedValues[key],
      };
    }
    sendData(url, formData);
  };

  const clearForm = () => {
    setSelectedValues({});
  };

  return (
    <div className="azzardometro">
      {results.error && (
        <Container textAlign="center" className="error">
          Errore: {results.text}
        </Container>
      )}
      {!formSubmitted && (
        <>
          {questions.map((question, index) => {
            return (
              <div className="question" key={'question-' + index}>
                <div className="text">{question.text}</div>
                <div className="options">
                  {Object.keys(question.options).map(value => (
                    <Button
                      onClick={() => handleClick(index, value)}
                      active={selectedValues[index] === value}
                      key={'option-' + index + '-' + value}
                    >
                      {question.options[value]}
                    </Button>
                  ))}
                </div>
              </div>
            );
          })}
        </>
      )}
      {results.error && (
        <Container textAlign="center" className="error">
          Errore: {results.text}
        </Container>
      )}
      {!formSubmitted && (
        <Container textAlign="center" className="submit-buttons">
          <Button primary onClick={onSubmit} size="massive">
            Calcola
          </Button>
          <Button
            default
            onClick={() => {
              clearForm();
            }}
            size="massive"
          >
            Ricomincia il test..
          </Button>
        </Container>
      )}

      {formSubmitted && (
        <div className="results">
          {!results.error && (
            <>
              <h3>
                Hai totalizzato
                <span className="points">{results.points} punti </span>
              </h3>
              <div>{results.text}</div>
              <div className="back">
                <Button
                  primary
                  size="big"
                  onClick={() => {
                    setSelectedValues({});
                    setFormSubmitted(false);
                  }}
                >
                  Rifai il test
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

GamblingForm.propTypes = {
  location: PropTypes.any,
};

export default connect(mapStateToProps, mapDispatchToProps)(GamblingForm);
