/**
 * View image block.
 * @module components/Blocks/FullImage/View
 */

import React from 'react';
import cx from 'classnames';

/**
 * View image block class.
 * @class View
 * @extends Component
 */
const View = () => {
  return <div className={cx('block break-block')}></div>;
};

export default View;
