/**
 * View for CPGI page.
 * @module components/GamblingForm/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import GamblingForm from './GamblingForm';
import { Container } from 'semantic-ui-react';

const GamblingFormView = ({ location, content }) => {
  // console.log('View content: ', content);
  // console.log('View location: ', location);
  return (
    <Container>
      <h1>{content.title}</h1>
      <div
        dangerouslySetInnerHTML={{
          __html: content.text.data,
        }}
      ></div>
      <GamblingForm location={location} />
    </Container>
  );
};

GamblingFormView.propTypes = {
  location: PropTypes.object.isRequired,
  content: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default GamblingFormView;
