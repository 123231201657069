import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import redraft from 'redraft';
import { settings } from '~/config';
import { LinkToWidget } from '@package/components';

const SidebarBlock = ({
  data,
  block,
  onChangeBlock,
  openObjectBrowser,
  required = false,
  intl,
}) => {
  let blockTitle = data.title
    ? redraft(data.title, settings.ToHTMLRenderers, settings.ToHTMLOptions)
    : null;

  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage id="Subblock" defaultMessage="Sottoblocco" />:
          {blockTitle}
        </h2>
      </header>

      <>
        <Segment className="form">
          <LinkToWidget
            data={data}
            openObjectBrowser={openObjectBrowser}
            onChange={(name, value) => {
              onChangeBlock(data.id, {
                ...data,
                [name]: value,
              });
            }}
          />
        </Segment>
      </>
    </Segment.Group>
  );
};

SidebarBlock.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  openObjectBrowser: PropTypes.func.isRequired,
};

export default injectIntl(SidebarBlock);
