/**
 * Logo component.
 * @module components/theme/Logo/Logo
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { defineMessages, injectIntl } from 'react-intl';
import { Image } from 'semantic-ui-react';

import LogoImage from '@package/logo.png';

const messages = defineMessages({
  site: {
    id: 'Site',
    defaultMessage: 'Stradanove',
  },
  plonesite: {
    id: 'Stradanove',
    defaultMessage: 'Stradanove',
  },
});

/**
 * Logo component class.
 * @function Logo
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component.
 */

const Logo = ({ footer, height, intl }) => (
  <Link
    to="/"
    title={intl.formatMessage(messages.site)}
    aria-label={intl.formatMessage(messages.site)}
  >
    <Image
      src={LogoImage}
      alt={intl.formatMessage(messages.plonesite)}
      title={intl.formatMessage(messages.plonesite)}
      height={height ? height : 130}
    />
    LA VIA DI COMUNICAZIONE PER I GIOVANI MODENESI
  </Link>
);

Logo.propTypes = {
  footer: PropTypes.bool,
  height: PropTypes.number,
};

export default injectIntl(Logo);
