import React from 'react';
import PropTypes from 'prop-types';

import TemplateWidget from '@plone/volto/components/manage/Blocks/Listing/TemplateWidget';

import { AlignWidget } from '@package/components';
import { CheckboxWidget } from '@plone/volto/components';
import TitleField from '@package/components/Blocks/Listing/Sidebar/TitleField';

const ListingStyle = props => {
  return (
    <>
      <TitleField {...props} />
      <TemplateWidget {...props} />
      <AlignWidget
        align={props.data.align}
        onChange={align => {
          props.onChangeBlock(props.block, {
            ...props.data,
            align: align,
          });
        }}
        alignments={['left', 'center']}
        label="Allineamento"
      />
      {props.data.template === 'list' && (
        <>
          <CheckboxWidget
            id="asColumn"
            title="Sulla prima colonna"
            required={false}
            description="Se impostato, il blocco occuperà la metà dello spazio disponibile in larghezza."
            value={props.data.asColumn ? props.data.asColumn : false}
            onChange={(id, checked) => {
              props.onChangeBlock(props.block, {
                ...props.data,
                [id]: checked,
              });
            }}
          />
        </>
      )}
    </>
  );
};

ListingStyle.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
};

export default ListingStyle;
