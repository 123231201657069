/**
 * View for Etilometro page.
 * @module components/Etilometro/View
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { getVocabulary, sendData } from '@package/actions/etilometro';
import Etilometro from './Etilometro';
import EtilometroResults from './EtilometroResults';

const mapStateToProps = state => {
  const { drinksVocabulary, results } = state.etilometro;
  return {
    drinksVocabulary,
    results,
  };
};
const mapDispatchToProps = { getVocabulary, sendData };

const EtilometroView = ({
  location,
  content,
  getVocabulary,
  sendData,
  results,
  drinksVocabulary,
}) => {
  useEffect(() => {
    getVocabulary(`${location.pathname}/@etilometro-form`);
  }, []);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formData, setFormData] = useState({});

  const submitForm = data => {
    setFormData(data);
    setFormSubmitted(true);
    const url = `${location.pathname}/@etilometro-form`;
    sendData(url, data);
  };

  const doTestAgain = () => {
    results = null;
    setFormSubmitted(false);
  };

  return (
    <Container>
      <h1>{content.title}</h1>
      <div
        dangerouslySetInnerHTML={{
          __html: content.text.data,
        }}
      ></div>

      {drinksVocabulary && !formSubmitted && (
        <Etilometro
          rows={5}
          drinksVocabulary={drinksVocabulary}
          submitForm={submitForm}
        />
      )}
      {formSubmitted && Object.keys(results).length !== 0 && (
        <EtilometroResults
          results={results}
          formData={formData}
          drinksVocabulary={drinksVocabulary}
          hasError={results.error}
          doTestAgain={doTestAgain}
        />
      )}
    </Container>
  );
};

EtilometroView.propTypes = {
  location: PropTypes.object.isRequired,
  content: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(EtilometroView);
