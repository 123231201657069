/**
 * Navigation actions.
 * @module actions/navigation/navigation
 */
export const GET_NAVIGATION_MENU = 'NAVIGATION_MENU';
/**
 * Get navigation.
 * @function getNavigation
 * @param {string} url Content url.
 * @param {number} depth Depth of the navigation tree.
 * @returns {Object} Get navigation action.
 */
export function getNavigationMenu(url, depth) {
  // Note: Depth can't be 0 in plone.restapi
  return {
    type: GET_NAVIGATION_MENU,
    request: {
      op: 'get',
      path: `${url}/@navigation${
        depth ? `?expand.navigation.depth=${depth}` : ''
      }`,
    },
  };
}
