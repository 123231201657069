/**
 * Edit text block.
 * @module components/manage/Blocks/Title/Edit
 */

import React from 'react';

import EditBlock from './Block/EditBlock';
import { Grid } from 'semantic-ui-react';
import {
  withDNDContext,
  SubblocksEdit,
  SubblocksWrapper,
} from 'volto-subblocks';

/**
 * Edit text block class.
 * @class Edit
 * @extends Component
 */
class Edit extends SubblocksEdit {
  getChunks = (size, array) => {
    size = size ? size : 3;
    var rows = array.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / size);
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }
      item.__index = index;
      resultArray[chunkIndex].push(item);
      return resultArray;
    }, []);
    return rows;
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (__SERVER__) {
      return <div />;
    }

    return (
      <SubblocksWrapper node={this.node}>
        <Grid stackable columns="equal">
          {this.getChunks(3, this.state.subblocks).map((row, rowindex) => (
            <Grid.Row columns={row.length} stretched key={rowindex}>
              {row.map((subblock) => (
                <Grid.Column key={subblock.id}>
                  <EditBlock
                    data={subblock}
                    index={subblock.__index}
                    selected={this.isSubblockSelected(subblock.__index)}
                    {...this.subblockProps}
                    openObjectBrowser={this.props.openObjectBrowser}
                  />
                </Grid.Column>
              ))}
            </Grid.Row>
          ))}
          <Grid.Row>
            {this.props.selected && (
              <Grid.Column>{this.renderAddBlockButton()}</Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      </SubblocksWrapper>
    );
  }
}

export default React.memo(withDNDContext(Edit));
