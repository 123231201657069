import React from 'react';
import PropTypes from 'prop-types';

import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { settings } from '~/config';
import { FormattedMessage, injectIntl } from 'react-intl';

import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';
import { Tags } from '@package/components';

const NewsItem = ({
  item,
  description,
  showImage = true,
  linkMore = true,
  isEditMode,
  intl,
}) => {
  const getImage = (item) => {
    let image = item[settings.listingPreviewImageField];
    if (image) {
      image = image.scales.preview.download;
    } else if (item.blocks) {
      //get image from first image block
      Object.keys(item.blocks).map((k) => {
        let block = item.blocks[k];
        if (block['@type'] === 'image') {
          image = block.url.includes(settings.apiPath)
            ? `${flattenToAppURL(block.url)}/@@images/image`
            : block.url;
        }
      });
    }
    if (!image) {
      image = DefaultImageSVG;
    }
    return image;
  };

  const image = getImage(item);

  const date = new Date(item.effective);

  return (
    <div className="news-item">
      {showImage && (
        <div className="header">
          <div className="image">
            <img
              src={image}
              alt={item.title}
              className={image === DefaultImageSVG ? 'default' : ''}
            />
          </div>
        </div>
      )}

      <div className="body">
        <Tags tags={item.subjects} />
        <div className="date">
          {date.toLocaleDateString(intl.locale, {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
          })}
        </div>
        <ConditionalLink
          to={flattenToAppURL(item['@id'])}
          condition={!linkMore && !isEditMode}
          aria-label={item.title ? item.title : item.id}
        >
          <h3>{item.title ? item.title : item.id}</h3>
        </ConditionalLink>

        {description && item.description && <div>{item.description}</div>}
      </div>
      {linkMore && (
        <div className="footer">
          <ConditionalLink
            to={flattenToAppURL(item['@id'])}
            condition={true}
            aria-label="Continua a leggere"
          >
            <FormattedMessage
              id="Keep reading"
              defaultMessage="Continua a leggere"
            />
          </ConditionalLink>
        </div>
      )}
    </div>
  );
};

NewsItem.propTypes = {
  item: PropTypes.object.isRequired,
  description: PropTypes.bool,
  showImage: PropTypes.bool,
  linkMore: PropTypes.bool,
};

export default injectIntl(NewsItem);
