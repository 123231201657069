/**
 * Gambling form reducer.
 * @module reducers/etilometro/etilometroReducer
 */

import { GET_VOCABULARY, ETILOMETRO_SEND_DATA } from '../actions/etilometro'

const initialState = {
    error: null,
    hasErrror: false,
    results: {},
    loadingResults: false,
    drinksVocabulary: null
}

export const etilometroReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case `${ETILOMETRO_SEND_DATA}_PENDING`:
            return {
                ...state,
                loadingResults: true
            };
        case `${ETILOMETRO_SEND_DATA}_SUCCESS`:
            return {
                ...state,
                results: action.result,
                loadingResults: false
            };
        case `${ETILOMETRO_SEND_DATA}_FAIL`:
            return {
                ...state,
                error: action.error,
                hasError: true,
                loadingResults: false
            };
        case `${GET_VOCABULARY}_PENDING`:
            return {
                ...state,
                loadingResults: true
            };
        case `${GET_VOCABULARY}_SUCCESS`:
            return {
                ...state,
                drinksVocabulary: action.result.items,
                loadingResults: false
            };
        case `${GET_VOCABULARY}_FAIL`:
            return {
                ...state,
                error: action.error,
                hasError: true,
                loadingResults: false
            };
        default:
            return state;
    }
}
