/**
 * Etilometro component.
 * @module components/Etilometro/Etilometro
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import pickBy from 'lodash/pickBy';
import values from 'lodash/values';

const _initializeRows = rows => {
  let rowsValues = {};
  for (let i = 0; i < rows; i++) {
    rowsValues[`row-${i}`] = {
      selectValue: '',
      quantityValue: '',
    };
  }
  return rowsValues;
};

const DEFAULT_GENRE = true;
const DEFAULT_HASEATEN = true;
const DEFAULT_WEIGHT = '';

const Etilometro = ({ rows, drinksVocabulary, submitForm }) => {
  const [rowsValues, setRowsValues] = useState(() => _initializeRows(rows));
  const [genreCheckboxes, setGenreCheckboxes] = useState(DEFAULT_GENRE);
  const [hasEatenCheckboxes, setHasEatenCheckboxes] = useState(
    DEFAULT_HASEATEN,
  );
  const [weight, setWeight] = useState(DEFAULT_WEIGHT);
  const [formErrors, setFormErrors] = useState('');

  const _renderRows = () => {
    let rows = [];
    for (const rowName in rowsValues) {
      const rowValue = rowsValues[rowName];

      const row = (
        <Form.Group inline key={rowName}>
          <select
            value={rowValue.selectValue}
            name={rowName}
            onChange={e => handleChange(e)}
          >
            <option value=""> Cosa? </option>
            {drinksVocabulary &&
              drinksVocabulary.map((drink, key) => (
                <option key={`option-${key}`} value={drink.token}>
                  {drink.title}
                </option>
              ))}
          </select>
          <input
            type="text"
            placeholder="Quanto?"
            className="quantity"
            value={rowValue.quantityValue}
            name={rowName}
            required={rowValue.selectValue}
            onChange={e => handleChange(e)}
          />
        </Form.Group>
      );
      rows.push(row);
    }

    return rows;
  };

  const validateRow = value => {
    return (
      !isNaN(value.quantityValue) &&
      Number.isInteger(Number(value.quantityValue)) &&
      !!value.selectValue
    );
  };

  const validateForm = () => {
    if (!weight) {
      return false;
    }
    const valid = pickBy(rowsValues, validateRow);
    return Object.keys(valid).length !== 0;
  };

  const clearForm = () => {
    setRowsValues(_initializeRows(rows));
    setGenreCheckboxes(DEFAULT_GENRE);
    setHasEatenCheckboxes(DEFAULT_HASEATEN);
    setWeight(DEFAULT_WEIGHT);
  };

  const handleSubmit = event => {
    event.preventDefault();
    // MB - check for required fields
    const formIsValid = validateForm();
    const genre = genreCheckboxes;
    const hasEaten = hasEatenCheckboxes;
    if (formIsValid) {
      const _selectedDrinks = values(pickBy(rowsValues, validateRow));
      const formData = _selectedDrinks.map(drink => ({
        drink: drink.selectValue,
        quantity: drink.quantityValue,
        sex: genre ? 'M' : 'F',
        weight: weight,
        hasEaten: hasEaten ? 1 : 0,
      }));
      submitForm({ formData });
    } else {
      const _formErrors = `Inserisci tutti i  campi richiesti`;
      setFormErrors(_formErrors);
    }
  };

  const handleChange = event => {
    const target = event.target;
    let value;
    const name = target.name;

    if (target.type.includes('select')) {
      value = target.value;
      setRowsValues(prevRows => ({
        ...prevRows,
        [name]: {
          ...prevRows[name],
          selectValue: value,
        },
      }));
    }
    if (target.type.includes('text')) {
      value = target.value;
      if (name.includes('row')) {
        setRowsValues(prevRows => ({
          ...prevRows,
          [name]: {
            ...prevRows[name],
            quantityValue: value,
          },
        }));
      } else if (name.includes('weight')) {
        setWeight(value);
      }
    }
    if (target.type.includes('radio')) {
      value = target.checked;
      if (name.includes('genre')) {
        setGenreCheckboxes(!genreCheckboxes);
      } else if (name.includes('hasEaten')) {
        setHasEatenCheckboxes(!hasEatenCheckboxes);
      }
    }
  };
  return (
    <>
      {drinksVocabulary && (
        <Form onSubmit={event => handleSubmit(event)} className="etilometro">
          <h2 className="plaster">Ho bevuto..</h2>
          {_renderRows()}

          <Form.Group inline>
            <label>Quando ho bevuto ero:</label>
            <Form.Field>
              <label>
                <input
                  type="radio"
                  name="hasEaten"
                  checked={hasEatenCheckboxes}
                  onChange={e => handleChange(e)}
                />
                A stomaco pieno
              </label>
            </Form.Field>
            <Form.Field>
              <label>
                <input
                  type="radio"
                  name="hasEaten"
                  checked={!hasEatenCheckboxes}
                  onChange={e => handleChange(e)}
                />
                A stomaco vuoto
              </label>
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <label>Sesso: </label>
            <Form.Field>
              <label className="radio">
                <input
                  type="radio"
                  name="genre"
                  checked={genreCheckboxes}
                  onChange={e => handleChange(e)}
                />
                M
              </label>
            </Form.Field>
            <Form.Field>
              <label className="radio">
                <input
                  type="radio"
                  name="genre"
                  checked={!genreCheckboxes}
                  onChange={e => handleChange(e)}
                />
                F
              </label>
            </Form.Field>

            <Form.Field>
              <input
                type="text"
                name="weight"
                required
                placeholder="Peso (kg)"
                value={weight}
                onChange={e => handleChange(e)}
              />
            </Form.Field>
          </Form.Group>

          {formErrors && <div className="errors">{formErrors}</div>}

          <Form.Group className="buttons">
            <Form.Button
              type="submit"
              size="big"
              secondary
              content="Dai... Calcola!"
            />
            <Form.Button
              type="button"
              size="big"
              default
              onClick={() => {
                clearForm();
              }}
              content="Cancella... Ricomincio!"
            />
          </Form.Group>
        </Form>
      )}
    </>
  );
};

Etilometro.propTypes = {
  drinksVocabulary: PropTypes.arrayOf(PropTypes.object).isRequired,
  submitForm: PropTypes.func.isRequired,
  rows: PropTypes.number,
};
Etilometro.defaultProps = {
  rows: 5,
};

export default Etilometro;
