/**
 * View image block.
 * @module components/Blocks/FullImage/View
 */

import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import { settings } from '~/config';
import { flattenToAppURL } from '@plone/volto/helpers';
import { ButtonLink } from '~/components';

/**
 * View image block class.
 * @class View
 * @extends Component
 */
const View = ({ data, detached }) => {
  const overlayText =
    data.title || data.description || data.href ? (
      <div className="overlay-text">
        {data.title ? <div className="title">{data.title}</div> : null}
        {data.description ? (
          <div className="description mobile hidden">{data.description}</div>
        ) : null}

        <ButtonLink
          to={data.href}
          title={data.linkTitle}
          targetBlank={data.openLinkInNewTab}
          showArrow={data.showArrowOnLink}
          _className="basic"
        />
      </div>
    ) : null;

  return (
    <div
      className={cx('block fullImage', {
        detached,
      })}
    >
      {data.url && (
        <div
          className={cx(
            'content',
            {
              /*'full-width': data.align === 'full'*/
            },
            data.size,
          )}
        >
          {data.overlay && <div className="overlay" />}
          <img
            src={
              data.url.includes(settings.apiPath)
                ? `${flattenToAppURL(data.url)}/@@images/image`
                : data.url
            }
            className="bg"
            alt={data.alt || ''}
          />
          {overlayText}
        </div>
      )}
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
