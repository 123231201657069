/**
 * ViewBlock.
 * @module components/manage/Blocks/Text/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import redraft from 'redraft';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { settings } from '~/config';

/**
 * ViewBlock class.
 * @class ViewBlock
 * @extends Component
 */
const ViewBlock = ({ data, className }) => {
  let content = (
    <div className={cx(className, 'single-block')}>
      {data.title && (
        <div className="title">
          {redraft(
            data.title,
            settings.ToHTMLRenderers,
            settings.ToHTMLOptions,
          )}
        </div>
      )}
      {data.description && (
        <div className="description">
          {redraft(
            data.description,
            settings.ToHTMLRenderers,
            settings.ToHTMLOptions,
          )}
        </div>
      )}
    </div>
  );

  if (data.href) {
    const isReallyExternal =
      (data.href.startsWith('http') || data.href.startsWith('https')) &&
      !data.href.includes(settings.apiPath);
    if (isReallyExternal) {
      content = (
        <a
          target={data.openLinkInNewTab ? '_blank' : null}
          href={data.href}
          aria-label={data.href}
        >
          {content}
        </a>
      );
    } else {
      content = (
        <Link
          to={data.href.replace(settings.apiPath, '')}
          target={data.openLinkInNewTab ? '_blank' : null}
          aria-label={data.href}
        >
          {content}
        </Link>
      );
    }
  }

  return content;
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ViewBlock.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ViewBlock;
