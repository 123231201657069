/**
 * Document view component.
 * @module components/theme/View/EventListingView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import { Helmet } from '@plone/volto/helpers';
import { Container, Grid } from 'semantic-ui-react';
import { EventItem } from '@package/components';

/**
 * List view component class.
 * @function EventListingView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */

const messages = defineMessages({
  old_events: {
    id: 'Old events',
    defaultMessage: 'Eventi passati',
  },
});

const EventListingView = ({ content, intl }) => {
  content.items.sort(function(a, b) {
    let dateA = a.start ? new Date(a.start) : null;
    let dateB = b.start ? new Date(b.start) : null;

    if (dateA && dateB) {
      return dateA > dateB ? 1 : -1;
    } else if (dateA != null) {
      return 1;
    } else if (dateB != null) {
      return -1;
    }
    return 0;
  });

  const getStartOfDay = date => {
    if (date) {
      let d = typeof date === Date ? date : new Date(date);
      d.setHours(0);
      d.setMinutes(0);
      d.setSeconds(0);
      d.setMilliseconds(0);
      return d;
    }
    return null;
  };

  let events = content.items.filter(item => {
    return item['@type'] === 'Event';
  });

  let nextEvents = events.filter(item => {
    if (item.start) {
      return getStartOfDay(item.start) >= getStartOfDay(new Date());
    }
    return false;
  });

  let oldEvents = events.filter(item => {
    if (item.start) {
      return getStartOfDay(item.start) < getStartOfDay(new Date());
    }
    return false;
  });

  return (
    <Container id="page-home">
      <Helmet title={content.title} />

      <section id="content-core" className="event-listing-view">
        <h1 className="documentFirstHeading">{content.title}</h1>
        {content.description && (
          <p className="documentDescription">{content.description}</p>
        )}

        <Grid stackable className="listing">
          <Grid.Row stretched>
            {nextEvents.map(item => (
              <Grid.Column width={3} className="listing-item" key={item['@id']}>
                <EventItem
                  item={item}
                  description={true}
                  defaultImage={false}
                />
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>

        <h2>{intl.formatMessage(messages.old_events)}</h2>
        <Grid stackable className="listing">
          <Grid.Row stretched>
            {oldEvents.map(item => (
              <Grid.Column width={3} className="listing-item" key={item['@id']}>
                <EventItem
                  item={item}
                  description={true}
                  defaultImage={false}
                />
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
      </section>
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
EventListingView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        '@id': PropTypes.string,
        '@type': PropTypes.string,
        description: PropTypes.string,
        start: PropTypes.any,
        end: PropTypes.any,
        title: PropTypes.string,
        url: PropTypes.string,
        image: PropTypes.any,
        subjects: PropTypes.any,
        blocks: PropTypes.any,
      }),
    ),
  }).isRequired,
};

export default injectIntl(EventListingView);
