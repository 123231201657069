/**
 * Header component.
 * @module components/theme/Header/Header
 */

import React, { Component } from 'react';
import { Container, Segment, Icon, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import {
  //Anontools,
  Logo,
  Navigation,
  SearchWidget,
} from '@plone/volto/components';

import { SocialHeader } from '~/components';

/**
 * Header component class.
 * @class Header
 * @extends Component
 */
class Header extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    token: PropTypes.string,
    pathname: PropTypes.string.isRequired,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    token: null,
  };

  state = { showSearch: false };

  toggleSearch = (ev, value) => {
    ev && ev.preventDefault();
    this.setState((state) => ({
      showSearch: value != null ? value : !state.showSearch,
    }));
  };
  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <Segment basic className="header-wrapper" role="banner">
        <Container>
          <div className="header">
            <div className="top-header">
              <div className="header-col left" />
              <div className="header-col">
                <div className="logo">
                  <Logo />
                </div>
              </div>
              <div className="header-col right">
                {/*!this.props.token && (
                  <div className="tools">
                    <Anontools />
                  </div>
                )*/}
                <SocialHeader />
                <div className="search">
                  <a
                    href="#"
                    onClick={this.toggleSearch}
                    title="Mostra la ricerca"
                    aria-label="Mostra la ricerca"
                  >
                    <Icon name="search" />
                  </a>
                </div>
              </div>
            </div>
            <div className="site-menu">
              <Navigation pathname={this.props.pathname} />
            </div>
          </div>
        </Container>
        <SearchWidget
          pathname={this.props.pathname}
          show={this.state.showSearch}
          toggleSearch={(toggle) => this.toggleSearch(null, toggle)}
        />
      </Segment>
    );
  }
}

export default connect((state) => ({
  token: state.userSession.token,
}))(Header);
