/**
 * Root reducer.
 * @module reducers/root
 */

import defaultReducers from '@plone/volto/reducers';
import { addonReducers } from '~/config';
import { gamblingFormReducer } from './gamblingForm';
import { etilometroReducer } from './etilometro';
import { keywordColorsReducer } from './keywordColors';
import { socialInfosReducer } from './socialInfos';
import navigationMenu from './navigationMenu';

/**
 * Root reducer.
 * @function
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
const reducers = {
  ...defaultReducers,
  ...addonReducers,
  // Add your reducers here
  gamblingForm: gamblingFormReducer,
  etilometro: etilometroReducer,
  keywordColors: keywordColorsReducer,
  navigationMenu: navigationMenu,
  socialInfos: socialInfosReducer,
};

export default reducers;
