import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Form, Grid, Button } from 'semantic-ui-react';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { TextWidget } from '@plone/volto/components';

const messages = defineMessages({
  title: {
    id: 'Title',
    defaultMessage: 'Titolo',
  },
});

const TitleField = props => {
  return (
    <>
      <TextWidget
        id="title"
        title={props.intl.formatMessage(messages.title)}
        required={false}
        value={props.data.title}
        onChange={(name, value) => {
          props.onChangeBlock(props.block, {
            ...props.data,
            [name]: value,
          });
        }}
      />
      <Form.Field inline required={true} id="field-title">
        <Grid>
          <Grid.Row stretched>
            <Grid.Column width="4"></Grid.Column>
            <Grid.Column width="8">
              <div className="color-selector">
                <div>
                  <FormattedMessage id="Color" defaultMessage="Colore" />
                </div>
                <Button
                  circular
                  className="icon"
                  onClick={(e, obj) => {
                    props.onChangeBlock(props.block, {
                      ...props.data,
                      titleColor: 'darkwhite',
                    });
                  }}
                  icon={
                    props.data.titleColor == 'darkwhite' ||
                    props.data.titleColor == null
                      ? 'check'
                      : ''
                  }
                />
                <Button
                  circular
                  className="icon lightblue"
                  onClick={(e, obj) => {
                    props.onChangeBlock(props.block, {
                      ...props.data,
                      titleColor: 'lightblue',
                    });
                  }}
                  icon={props.data.titleColor === 'lightblue' ? 'check' : ''}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form.Field>
    </>
  );
};

TitleField.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
};

export default injectIntl(TitleField);
