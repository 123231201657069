export const GET_VOCABULARY = 'GET_VOCABULARY';
export const ETILOMETRO_SEND_DATA = 'ETILOMETRO_SEND_DATA';

export const getVocabulary = url => {
  const request = {
    op: 'get',
    path: url,
    type: 'application/json',
    headers: {
      Accept: 'application/json',
    },
  };
  //console.log('Getting vocabulary from Plone: ', request);
  return { type: GET_VOCABULARY, request };
};

export const sendData = (url, payload) => {
  const request = {
    op: 'post',
    path: url,
    data: payload,
    type: 'application/json',
    headers: {
      Accept: 'application/json',
    },
  };
  //console.log('Sending form data to Plone: ', request);
  return { type: ETILOMETRO_SEND_DATA, request };
};
