import DefaultItem from './DefaultItem';
import EventItem from './EventItem';
import FileItem from './FileItem';
import LinkItem from './LinkItem';
import ImageItem from './ImageItem';
import NewsItem from './NewsItem';

const ItemTemplates = {
  Default: DefaultItem,
  Event: EventItem,
  File: FileItem,
  Link: LinkItem,
  Image: ImageItem,
  'News Item': NewsItem,
};

export default ItemTemplates;
