/**
 * Edit image block.
 * @module components/Blocks/YoungER/Edit
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { Grid, Input, TextArea } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import { settings } from '~/config';

import { SidebarPortal } from '@plone/volto/components';
import { createContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';

import { ImageDropzoneWidget, TextEditorWidget } from '@package/components';

import Sidebar from './Sidebar';

const messages = defineMessages({
  ImageBlockInputPlaceholder: {
    id: 'Browse the site, drop an image, or type an URL',
    defaultMessage: "Cerca nel sito, trascina un'immagine, o inserisci un URL",
  },
  title: {
    id: 'Title',
    defaultMessage: 'Titolo',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Descrizione',
  },
});

/**
 * Edit image block class.
 * @class Edit
 * @extends Component
 */
class Edit extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    selected: PropTypes.bool.isRequired,
    block: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    content: PropTypes.objectOf(PropTypes.any).isRequired,
    request: PropTypes.shape({
      loading: PropTypes.bool,
      loaded: PropTypes.bool,
    }).isRequired,
    pathname: PropTypes.string.isRequired,
    onChangeBlock: PropTypes.func.isRequired,
    onSelectBlock: PropTypes.func.isRequired,
    onDeleteBlock: PropTypes.func.isRequired,
    onFocusPreviousBlock: PropTypes.func.isRequired,
    onFocusNextBlock: PropTypes.func.isRequired,
    handleKeyDown: PropTypes.func.isRequired,
    createContent: PropTypes.func.isRequired,
    openObjectBrowser: PropTypes.func.isRequired,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);

    if (!__SERVER__) {
      if (!this.props.data.block1) {
        this.props.data.block1 = { url: '' };
      }
      if (!this.props.data.block2) {
        this.props.data.block2 = { url: '' };
      }
      if (!this.props.data.block3) {
        this.props.data.block3 = { url: '' };
      }
      this.state = { subblockFocused: 0 };
    }
  }

  onChangeInputField = (field, editorState) => {
    this.setState({ editorState }, () => {
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        [field]: editorState.getCurrentContent().getPlainText(),
      });
    });
  };

  node = React.createRef();

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const subblockFocused = this.state.subblockFocused;
    return (
      <div className="block">
        <h2 className="plaster">
          <TextEditorWidget
            data={this.props.data}
            fieldName="title"
            block={this.props.block}
            selected={true}
            onChangeBlock={ret =>
              this.props.onChangeBlock(this.props.block, ret)
            }
            placeholder={this.props.intl.formatMessage(messages.title)}
            inlineToolbarPlugin={false}
          />
        </h2>

        <Grid stackable>
          <Grid.Row stretched>
            <Grid.Column tablet={16} computer={8} className="col-left">
              <div
                onClick={() => {
                  this.setState({ subblockFocused: 0 });
                }}
              >
                <ImageDropzoneWidget
                  {...this.props}
                  data={this.props.data.block1}
                  onChangeBlock={(name, value) =>
                    this.props.onChangeBlock(this.props.block, {
                      ...this.props.data,
                      block1: {
                        [name]: value,
                      },
                    })
                  }
                >
                  <div className="big-map">
                    <img
                      alt="image1"
                      src={
                        this.props.data.block1?.url?.includes(settings.apiPath)
                          ? `${flattenToAppURL(
                              this.props.data.block1.url,
                            )}/@@images/image`
                          : this.props.data.block1.url
                      }
                    />

                    <div className="title">
                      <Input
                        id="title"
                        name="title"
                        value={this.props.data.block1.title || ''}
                        onChange={({ target }) => {
                          let value =
                            target.value === '' ? undefined : target.value;
                          this.props.onChangeBlock(this.props.block, {
                            ...this.props.data,
                            block1: {
                              ...this.props.data.block1,
                              title: value,
                            },
                          });
                        }}
                        placeholder={this.props.intl.formatMessage(
                          messages.title,
                        )}
                      />
                    </div>
                  </div>
                </ImageDropzoneWidget>
              </div>
            </Grid.Column>
            <Grid.Column tablet={16} computer={4} className="col-right">
              <div
                className="block"
                onClick={() => {
                  this.setState({ subblockFocused: 1 });
                }}
              >
                <ImageDropzoneWidget
                  {...this.props}
                  data={this.props.data.block2}
                  onChangeBlock={(name, value) =>
                    this.props.onChangeBlock(this.props.block, {
                      ...this.props.data,
                      block2: {
                        [name]: value,
                      },
                    })
                  }
                >
                  <div>
                    <img
                      alt="image2"
                      src={
                        this.props.data.block2.url.includes(settings.apiPath)
                          ? `${flattenToAppURL(
                              this.props.data.block2.url,
                            )}/@@images/image`
                          : this.props.data.block2.url
                      }
                    />
                    <div className="title">
                      <TextArea
                        id="title"
                        name="title"
                        value={this.props.data.block2.title || ''}
                        onChange={({ target }) => {
                          let value =
                            target.value === '' ? undefined : target.value;
                          this.props.onChangeBlock(this.props.block, {
                            ...this.props.data,
                            block2: {
                              ...this.props.data.block2,
                              title: value,
                            },
                          });
                        }}
                        placeholder={this.props.intl.formatMessage(
                          messages.title,
                        )}
                        rows={1}
                      />
                    </div>
                    <div className="description">
                      <TextArea
                        id="description"
                        name="description"
                        value={this.props.data.block2.description || ''}
                        onChange={({ target }) => {
                          let value =
                            target.value === '' ? undefined : target.value;
                          this.props.onChangeBlock(this.props.block, {
                            ...this.props.data,
                            block2: {
                              ...this.props.data.block2,
                              description: value,
                            },
                          });
                        }}
                        placeholder={this.props.intl.formatMessage(
                          messages.description,
                        )}
                        rows={2}
                      />
                    </div>
                  </div>
                </ImageDropzoneWidget>
              </div>

              <div
                className="block"
                onClick={() => {
                  this.setState({ subblockFocused: 2 });
                }}
              >
                <ImageDropzoneWidget
                  {...this.props}
                  data={this.props.data.block3}
                  onChangeBlock={(name, value) =>
                    this.props.onChangeBlock(this.props.block, {
                      ...this.props.data,
                      block3: {
                        [name]: value,
                      },
                    })
                  }
                >
                  <div>
                    <img
                      alt="image3"
                      src={
                        this.props.data.block3.url.includes(settings.apiPath)
                          ? `${flattenToAppURL(
                              this.props.data.block3.url,
                            )}/@@images/image`
                          : this.props.data.block3.url
                      }
                    />
                  </div>

                  <div className="description">
                    <TextArea
                      id="description"
                      name="description"
                      value={this.props.data.block3.description || ''}
                      onChange={({ target }) => {
                        let value =
                          target.value === '' ? undefined : target.value;
                        this.props.onChangeBlock(this.props.block, {
                          ...this.props.data,
                          block3: {
                            ...this.props.data.block3,
                            description: value,
                          },
                        });
                      }}
                      placeholder={this.props.intl.formatMessage(
                        messages.description,
                      )}
                      rows={2}
                    />
                  </div>
                </ImageDropzoneWidget>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <SidebarPortal selected={this.props.selected}>
          <Sidebar {...this.props} subblockFocused={subblockFocused} />
        </SidebarPortal>
      </div>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    state => ({
      request: state.content.create,
      content: state.content.data,
    }),
    { createContent },
  ),
)(Edit);
