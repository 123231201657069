/**
 * Search widget component.
 * @module components/theme/SearchWidget/SearchWidget
 */

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Input } from 'semantic-ui-react';
import { compose } from 'redux';
import { PropTypes } from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

import { Icon } from '@plone/volto/components';
import zoomSVG from '@plone/volto/icons/zoom.svg';
import clearSVG from '@plone/volto/icons/clear.svg';

const messages = defineMessages({
  search: {
    id: 'Search',
    defaultMessage: 'Search',
  },
  searchSite: {
    id: 'Search Site',
    defaultMessage: 'Search Site',
  },
  closeSearch: {
    id: 'Close search',
    defaultMessage: 'Close search',
  },
});

/**
 * SearchWidget component class.
 * @class SearchWidget
 * @extends Component
 */
class SearchWidget extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    pathname: PropTypes.string.isRequired,
    show: PropTypes.bool,
    toggleSearch: PropTypes.any,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    this.onChangeText = this.onChangeText.bind(this);
    this.onChangeSection = this.onChangeSection.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      text: '',
      section: false,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutsideSearch);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutsideSearch);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.show) {
      this.SearchableText.focus();
    }
  }
  /**
   * On change text
   * @method onChangeText
   * @param {object} event Event object.
   * @param {string} value Text value.
   * @returns {undefined}
   */
  onChangeText(event, { value }) {
    this.setState({
      text: value,
    });
  }

  /**
   * On change section
   * @method onChangeSection
   * @param {object} event Event object.
   * @param {bool} checked Section checked.
   * @returns {undefined}
   */
  onChangeSection(event, { checked }) {
    this.setState({
      section: checked,
    });
  }

  /**
   * Submit handler
   * @method onSubmit
   * @param {event} event Event object.
   * @returns {undefined}
   */
  onSubmit(event) {
    const section = this.state.section ? `&path=${this.props.pathname}` : '';
    this.props.history.push(
      `/search?SearchableText=${this.state.text}${section}`,
    );
    this.props.toggleSearch(false);
    event.preventDefault();
  }

  handleClickOutsideSearch = event => {
    if (this.props.show) {
      if (this.searchNode && !this.searchNode.contains(event.target)) {
        this.props.toggleSearch(false);
      }
    }
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <div
        className={(this.props.show ? '' : 'hide') + ' searchForm'}
        ref={node => {
          this.searchNode = node;
        }}
      >
        <Form action="/search" onSubmit={this.onSubmit}>
          <Form.Field className="searchbox">
            <button
              aria-label={this.props.intl.formatMessage(messages.search)}
              onClick={this.onSubmit}
            >
              <Icon name={zoomSVG} size="18px" />
            </button>
            <Input
              aria-label={this.props.intl.formatMessage(messages.search)}
              onChange={this.onChangeText}
              ref={input => {
                this.SearchableText = input;
              }}
              name="SearchableText"
              value={this.state.text}
              transparent
              placeholder={this.props.intl.formatMessage(messages.searchSite)}
              title={this.props.intl.formatMessage(messages.search)}
            />
            <button
              aria-label={this.props.intl.formatMessage(messages.closeSearch)}
              onClick={() => {
                this.props.toggleSearch(false);
              }}
            >
              <Icon name={clearSVG} size="18px" />
            </button>
          </Form.Field>
        </Form>
      </div>
    );
  }
}

export default compose(withRouter, injectIntl)(SearchWidget);
