/*
This file is autogenerated. Don't change it directly.
Instead, change the "addons" setting in your package.json file.
*/

import voltoCookieBanner from 'volto-cookie-banner';
import voltoMultilingualWidget from 'volto-multilingual-widget';
import voltoRssBlock from 'volto-rss-block';
import voltoSubblocks from 'volto-subblocks';

const load = (config) => {
  const addonLoaders = [voltoCookieBanner, voltoMultilingualWidget, voltoRssBlock, voltoSubblocks];
  if(!addonLoaders.every((el) => typeof el === "function")) {
    throw new TypeError(
      'Each addon has to provide a function applying its configuration to the projects configuration.',
    );
  }
  return addonLoaders.reduce((acc, apply) => apply(acc), config);
};
export default load;
