import {
  settings as defaultSettings,
  views as defaultViews,
  widgets as defaultWidgets,
  blocks as defaultBlocks,
} from '@plone/volto/config';

import React from 'react';
import * as config from '@plone/volto/config';

import imageFullSVG from '@plone/volto/icons/image-full.svg';
import ViewFullImage from '@package/components/Blocks/FullImage/View';
import EditFullImage from '@package/components/Blocks/FullImage/Edit';

import divideHorizontalSVG from '@plone/volto/icons/divide-horizontal.svg';
import ViewBreak from '@package/components/Blocks/Break/View';
import EditBreak from '@package/components/Blocks/Break/Edit';

import imageWideSVG from '@plone/volto/icons/image-wide.svg';
import ViewYoungER from '@package/components/Blocks/YoungER/View';
import EditYoungER from '@package/components/Blocks/YoungER/Edit';

import subtextSVG from '@plone/volto/icons/subtext.svg';
import ViewPageDescription from '@package/components/Blocks/PageDescription/View';
import EditPageDescription from '@package/components/Blocks/PageDescription/View';

import createInlineStyleButton from 'draft-js-buttons/lib/utils/createInlineStyleButton';
import createBlockStyleButton from 'draft-js-buttons/lib/utils/createBlockStyleButton';
import { Separator } from 'draft-js-inline-toolbar-plugin';

import Icon from '@plone/volto/components/theme/Icon/Icon';
import underlineSVG from '@plone/volto/icons/underline.svg';
import alignCenterSVG from '@plone/volto/icons/align-center.svg';
import alignJustifySVG from '@plone/volto/icons/align-justify.svg';
import textSVG from '@plone/volto/icons/text.svg';

import textBlocksSVG from '@plone/volto/icons/apps.svg';
import ViewTextBlocks from '@package/components/Blocks/TextBlocks/View';
import EditTextBlocks from '@package/components/Blocks/TextBlocks/Edit';

import formSVG from '@plone/volto/icons/form.svg';
import ViewContactForm from '@package/components/Blocks/ContactForm/View';
import EditContactForm from '@package/components/Blocks/ContactForm/Edit';

import GrigliaListingBlockTemplate from '@package/components/Blocks/Listing/Templates/GrigliaTemplate';
import ListListingBlockTemplate from '@package/components/Blocks/Listing/Templates/ListTemplate';
import PreviewListingBlockTemplate from '@package/components/Blocks/Listing/Templates/PreviewTemplate';

import iconsBlocksSVG from '@plone/volto/icons/dots.svg';
import ViewIconsBlocks from '@package/components/Blocks/IconsBlocks/View';
import EditIconsBlocks from '@package/components/Blocks/IconsBlocks/Edit';
import GamblingFormView from '@package/components/GamblingForm/View';
import EtilometroView from '@package/components/Etilometro/View';

import EventListingView from '@package/components/theme/View/EventListingView';
import EventView from '@package/components/theme/View/EventView';

import { rssBlock } from 'volto-rss-block';

import { MultilingualWidget } from 'volto-multilingual-widget';

const extendedBlockRenderMap = defaultSettings.extendedBlockRenderMap
  .update('align-center', (element = 'p') => element)
  .update('align-justify', (element = 'p') => element);

const addBreaklinesInline = (children) => {
  if (typeof children[0] == 'string') {
    const s = children[0].endsWith('\n')
      ? children[0].slice(0, -1)
      : children[0];

    if (s.split('\n').length > 1) {
      return s.split('\n').map((child, index) => (
        <React.Fragment key={child + index}>
          {child}
          <br />
        </React.Fragment>
      ));
    }
  }
  return children;
};

const blockStyleFn = (contentBlock) => {
  let r = defaultSettings.blockStyleFn(contentBlock);

  if (!r) {
    const type = contentBlock.getType();
    if (type === 'align-center') {
      r += ' align-center';
    }
    if (type === 'align-justify') {
      r += ' align-justify';
    }
  }

  return r;
};

const listBlockTypes = defaultSettings.listBlockTypes.concat([
  'align-center',
  'align-justify',
  'header-four',
  'header-five',
  'header-six',
]);

const ToHTMLRenderers = {
  ...defaultSettings.ToHTMLRenderers,
  blocks: {
    ...defaultSettings.ToHTMLRenderers.blocks,

    'align-center': (children, { keys }) =>
      children.map((child, i) => (
        <p key={keys[i]} className="align-center">
          {addBreaklinesInline([child[1][0]])}
        </p>
      )),
    'align-justify': (children, { keys }) =>
      children.map((child, i) => (
        <p key={keys[i]} className="align-justify">
          {addBreaklinesInline([child[1][0]])}
        </p>
      )),
  },
};

const UnderlineButton = createInlineStyleButton({
  style: 'UNDERLINE',
  children: <Icon name={underlineSVG} size="24px" />,
});
const AlignCenterButton = createBlockStyleButton({
  blockType: 'align-center',
  children: <Icon name={alignCenterSVG} size="24px" />,
});
const AlignJustifyButton = createBlockStyleButton({
  blockType: 'align-justify',
  children: <Icon name={alignJustifySVG} size="24px" />,
});
const HeaderFourButton = createBlockStyleButton({
  blockType: 'header-four',
  children: <Icon name={textSVG} size="18px" />,
});
const HeaderFiveButton = createBlockStyleButton({
  blockType: 'header-five',
  children: <Icon name={textSVG} size="16px" />,
});
const HeaderSixButton = createBlockStyleButton({
  blockType: 'header-six',
  children: <Icon name={textSVG} size="10px" />,
});

export const settings = {
  ...defaultSettings,
  matomoSiteId: 'XKqJa1npYR',
  matomoUrlBase: 'https://ingestion.webanalytics.italia.it/',
  richTextEditorInlineToolbarButtons: [
    HeaderFourButton,
    HeaderFiveButton,
    HeaderSixButton,
    Separator,
    AlignCenterButton,
    AlignJustifyButton,
    UnderlineButton,
    ...defaultSettings.richTextEditorInlineToolbarButtons,
  ],
  extendedBlockRenderMap: extendedBlockRenderMap,
  blockStyleFn: blockStyleFn,
  listBlockTypes: listBlockTypes,
  ToHTMLRenderers: ToHTMLRenderers,
  supportedLanguages: ['it'],
  defaultLanguage: 'it',
  devProxyToApiPath: 'http://localhost:9020/Plone',
};

export const views = {
  ...defaultViews,
  layoutViews: {
    ...defaultViews.layoutViews,
    event_listing: EventListingView,
  },
  contentTypesViews: {
    ...defaultViews.contentTypesViews,
    GamblingForm: GamblingFormView,
    Etilometro: EtilometroView,
    Event: EventView,
  },
};

export const widgets = {
  ...defaultWidgets,
  id: {
    ...defaultWidgets.id,
    cookie_consent_configuration: MultilingualWidget(),
  },
};

const customBlocks = {
  fullImage: {
    id: 'fullImage',
    title: 'Fascia con immagine',
    icon: imageFullSVG,
    group: 'media',
    view: ViewFullImage,
    edit: EditFullImage,
    restricted: false,
    mostUsed: true,
    security: {
      addPermission: [],
      view: [],
    },
    sidebarTab: 1,
  },
  break: {
    id: 'break',
    title: 'Interruzione di pagina',
    icon: divideHorizontalSVG,
    group: 'text',
    view: ViewBreak,
    edit: EditBreak,
    restricted: false,
    mostUsed: true,
    security: {
      addPermission: [],
      view: [],
    },
    sidebarTab: 1,
  },
  youngER: {
    id: 'youngER',
    title: 'YoungER',
    icon: imageWideSVG,
    group: 'media',
    view: ViewYoungER,
    edit: EditYoungER,
    restricted: false,
    mostUsed: true,
    security: {
      addPermission: [],
      view: [],
    },
    sidebarTab: 1,
  },

  pageDescription: {
    id: 'pageDescription',
    title: 'Descrizione di pagina',
    icon: subtextSVG,
    group: 'text',
    view: ViewPageDescription,
    edit: EditPageDescription,
    restricted: false,
    mostUsed: true,
    security: {
      addPermission: [],
      view: [],
    },
    sidebarTab: 1,
  },
  textBlocks: {
    id: 'textBlocks',
    title: 'Blocchi di testo',
    icon: textBlocksSVG,
    group: 'text',
    view: ViewTextBlocks,
    edit: EditTextBlocks,
    restricted: false,
    mostUsed: true,
    security: {
      addPermission: [],
      view: [],
    },
    sidebarTab: 1,
  },

  iconsBlocks: {
    id: 'iconsBlocks',
    title: 'Blocchi con icona',
    icon: iconsBlocksSVG,
    group: 'text',
    view: ViewIconsBlocks,
    edit: EditIconsBlocks,
    restricted: false,
    mostUsed: true,
    security: {
      addPermission: [],
      view: [],
    },
    sidebarTab: 1,
  },
  contactForm: {
    id: 'contactForm',
    title: 'Form di contatto',
    icon: formSVG,
    group: 'common',
    view: ViewContactForm,
    edit: EditContactForm,
    restricted: false,
    mostUsed: false,
    security: {
      addPermission: [],
      view: [],
    },
    sidebarTab: 1,
  },

  rssBlock,

  listing: {
    ...defaultBlocks.blocksConfig.listing,
    templates: {
      ...defaultBlocks.blocksConfig.listing.templates,
      griglia: { label: 'Griglia', template: GrigliaListingBlockTemplate },
      list: { label: 'Lista', template: ListListingBlockTemplate },
      preview: { label: 'Anteprima', template: PreviewListingBlockTemplate },
    },
  },
};

const blocksConfig = { ...defaultBlocks.blocksConfig, ...customBlocks };
delete blocksConfig.leadImage;

export const blocks = {
  ...defaultBlocks,
  blocksConfig: blocksConfig,
};

export const addonReducers = { ...config.addonReducers };
export const addonRoutes = [...(config.addonRoutes || [])];
