import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ConditionalLink } from '@plone/volto/components';
import { ButtonLink } from '@package/components';
import { flattenToAppURL } from '@plone/volto/helpers';

const ListTemplate = ({
  items,
  isEditMode,
  title,
  titleColor,
  linkMore,
  align,
  asColumn,
  intl,
}) => {
  return (
    <div className={cx('list-template', align, asColumn ? 'as-column' : '')}>
      {title && <h2 className={cx('square', titleColor)}>{title}</h2>}
      <div className="items">
        {items.map((item) => {
          const date =
            ['News Item', 'Event'].indexOf(item['@type']) >= 0
              ? new Date(item.effective)
              : null;

          return (
            <div className="listing-item " key={item['@id']}>
              <div className="header">
                {date && (
                  <div className="date">
                    {date.toLocaleDateString(intl.locale, {
                      day: '2-digit',
                      month: 'long',
                      year: 'numeric',
                    })}
                  </div>
                )}
                <h3>{item.title ? item.title : item.id}</h3>
              </div>

              {item.description && (
                <div className="body">{item.description}</div>
              )}

              <div className="footer">
                <ConditionalLink
                  to={flattenToAppURL(item['@id'])}
                  condition={!isEditMode}
                  aria-label="Continua a leggere"
                >
                  <FormattedMessage
                    id="Keep reading"
                    defaultMessage="Continua a leggere"
                  />
                </ConditionalLink>
              </div>
            </div>
          );
        })}
      </div>
      {linkMore?.href && (
        <div className="bottom">
          <ButtonLink
            to={linkMore.href}
            title={linkMore.title}
            color="purple"
            aria-label={linkMore.title}
          />
        </div>
      )}
    </div>
  );
};

ListTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default injectIntl(ListTemplate);
