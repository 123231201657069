/**
 * SideMenu block.
 * @module components/Blocks/SideMenu/SideMenu
 */

import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import { Menu, Grid } from 'semantic-ui-react';

const messages = defineMessages({
  inThisSection: {
    id: 'In this section',
    defaultMessage: 'In questa sezione',
  },
});

const EXCLUDE_TYPES = ['Image', 'File'];
/**
 * SideMenu class.
 * @class SideMenu
 * @extends Component
 */

const SideMenu = ({ intl, items, pathname, columnWidth }) => {
  if (items && pathname.length > 1) {
    //exclude children like Images or something else defined in EXCLUDE_TYPES
    let _items = items.filter(item => {
      return EXCLUDE_TYPES.indexOf(item['@type']) < 0 && !item.exclude_from_nav;
    });

    if (_items.length) {
      return (
        <Grid.Column
          className="sidemenu"
          tablet={12}
          computer={columnWidth}
          largeScreen={columnWidth}
          widescreen={columnWidth}
        >
          <Menu vertical secondary>
            <Menu.Item header>
              {intl.formatMessage(messages.inThisSection)}
            </Menu.Item>
            {_items.map(item => (
              <Menu.Item href={item['url']} link={true} key={item['@id']}>
                {item.title}
              </Menu.Item>
            ))}
          </Menu>
        </Grid.Column>
      );
    }
  }

  return null;
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
SideMenu.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string,
  columnWidth: PropTypes.number,
};

export default injectIntl(SideMenu);
