/**
 * SocialHeader component.
 * @module components/SocialHeader/SocialHeader
 */

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getSocialInfos } from '~/actions/socialInfos';
import { Icon } from 'semantic-ui-react';

/*
 * default values:
 *
 * facebook|facebook f|http://www.facebook.com/stradanove
 * twitter|twitter|https://twitter.com/Strada_nove
 * youtube|youtube|https://www.youtube.com/channel/UC7Kle8FEsvroyAGSUtbghMg
 * instagram|instagram|https://instagram.com/stradanove
 * e-mail|mail|mailto:info@stradanove.it
 *  <a href="https://twitter.com/Strada_nove">
 *    <Icon name="twitter" />
 *  </a>
 *  <a href="http://www.facebook.com/stradanove">
 *    <Icon name="facebook f" />
 *  </a>
 *  <a href="https://www.youtube.com/channel/UC7Kle8FEsvroyAGSUtbghMg">
 *    <Icon name="youtube" />
 *  </a>
 *  <a href="http://www.stradanove.it/instgr.png">
 *    <Icon name="instagram" />
 *  </a>
 *  <a href="mailto:####">
 *    <Icon name="mail" />
 *  </a>
 */

const SocialHeader = () => {
  const socialInfos = useSelector((state) => state.socialInfos.results);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSocialInfos());
  }, [dispatch]);

  return socialInfos?.length > 0 ? (
    <div className="social-header">
      {socialInfos.map((social) => {
        const [title, iconName, url] = social.split('|');

        return (
          <a
            href={url}
            rel="noopener noreferrer"
            target="_blank"
            title={title}
            key={url}
            aria-label={title}
          >
            <Icon name={iconName} />
          </a>
        );
      })}
    </div>
  ) : null;
};

export default SocialHeader;
